/*
  Ao adicionar um projeto novo, lembrar de manter sempre a propriedade TITLE
  com o nome do projeto em português, mesmo contendo uma key para tradução.

  O TITLE serve para a ordenação dos paineis na tela.
*/

export const projects = [
  {
    title: 'Vertentes',
    translation: 'vertentes',
    new: true,
    key: 'vertentes',
    envs: ['development', 'homolog', 'prod'],
    miniLogo: new URL('../../assets/imgs/full_vertentes.png', import.meta.url),
    fullLogo: new URL('../../assets/imgs/full_vertentes.png', import.meta.url),
    svgIcon: new URL('../../assets/imgs/full_vertentes.png', import.meta.url),
  },
  {
    title: 'Agronordeste',
    translation: 'agronordeste',
    new: true,
    key: 'agronordeste',
    envs: ['development', 'homolog', 'prod'],
    miniLogo: new URL('../../assets/imgs/full_agronordeste.png', import.meta.url),
    fullLogo: new URL('../../assets/imgs/full_agronordeste.png', import.meta.url),
    svgIcon: new URL('../../assets/imgs/full_agronordeste.png', import.meta.url),
  },
]
