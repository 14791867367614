import Alpine from 'alpinejs'
import AlpineI18n from 'alpinejs-i18n'
import $ from 'jquery'
import SetUpConfigs from '~/src/scripts/configs'
import Translations from './data/translations'

import header from '~/src/views/header.html'
import backToTop from '~/src/views/backToTop.html'
import footer from '~/src/views/footer.html'
import particles from '~/src/views/particles.html'
const includes = { header, backToTop, footer, particles }

window.Alpine = Alpine
window.$ = $

// Iniciando componentes.
$(function () {

  if (window.location.pathname.replace(/\//g, '') === 'plataformas') {
    window.location = '/plataformas/estatistica'
    return
  }

  // Criando eventos customizados.
  document.addEventListener("loadingScreen", function(e) {
    if (typeof e.detail !== 'boolean') throw new Error('Você precisa passar um estado para a tela de loading.')
    if (e.detail) $("#loading_screen").fadeIn(500)
    else $("#loading_screen").fadeOut(500)
  })

  document.addEventListener('alpine-i18n:ready', function () {
    window.AlpineI18n.create(localStorage.getItem('locale') ?? 'ptBR', Translations)
    window.AlpineI18n.fallbackLocale = 'ptBR'
  })

  ParseAllLoaders()
  SetUpConfigs()
  Alpine.plugin(AlpineI18n)
  Alpine.start()
})

async function ParseAllLoaders() {
  var nodes = $('[data-include]')

  for(const node of nodes) {
    var file = $(node).data('include')
    if (!Object.prototype.hasOwnProperty.call(includes, file)) continue
    await LoadPromised($(node), includes[file])
  }
  
  FireLoadingEvent(false)
}

function LoadPromised(content, path) {
  return new Promise((resolve) => {
    $(content).load(path, function() {
      resolve()
    })
  })
}

function FireLoadingEvent(detail) {
  const event = new CustomEvent('loadingScreen', { detail })
  document.dispatchEvent(event)
}