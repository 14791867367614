export const macros = [
  {
    title: 'Projeções do agro no Brasil',
    translation: 'macro_06',
    logo: new URL('../../assets/imgs/macro_projecoes.png', import.meta.url),
    key: 'projecoes-agro',
  },
  {
    title: 'Gestão da produção agrícola',
    translation: 'macro_05',
    logo: new URL('../../assets/imgs/macro_gestao.png', import.meta.url),
    key: 'prod-agricola',
  },
  {
    title: 'Sustentabilidade e meio ambiente',
    translation: 'macro_08',
    logo: new URL('../../assets/imgs/macro_sustentabilidade.png', import.meta.url),
    key: 'sustentabilidade',
  },
  {
    title: 'Agricultura digital',
    translation: 'macro_01',
    logo: new URL('../../assets/imgs/macro_digital.png', import.meta.url),
    key: 'agro-digital',
  },
  {
    title: 'Reforma agrária',
    translation: 'macro_07',
    logo: new URL('../../assets/imgs/macro_reforma_agraria.png', import.meta.url),
    key: 'reforma-agraria',
  },
  {
    title: 'Boletins climáticos para o agro',
    translation: 'macro_02',
    logo: new URL('../../assets/imgs/macro_climatico.png', import.meta.url),
    key: 'boletins',
  },
  {
    title: 'Documentos gerais',
    translation: 'macro_03',
    logo: new URL('../../assets/imgs/macro_docs_gerais.png', import.meta.url),
    key: 'docs-gerais',
  },
  {
    title: 'Documentos técnicos',
    translation: 'macro_04',
    logo: new URL('../../assets/imgs/macro_docs_tecnicos.png', import.meta.url),
    key: 'docs-tecnicos',
  },
]

export const documents = [
  {
    title: 'Perspectivas para a Agropecuária Vol. 9 - 2021/22',
    doc: new URL('../../assets/docs/Perspectivas para a Agropecuária.pdf', import.meta.url),
    categoria: 'Relatório',
    macros: ['projecoes-agro'],
    tipo: 'pdf',
    anos: ['2021', '2022'],
    parceiros: ['CONAB'],
  },
  {
    title: 'Perspectives Brazilian Grain Harvest 2020/21',
    doc: new URL('../../assets/docs/Brazilian Grain Harvest.pdf', import.meta.url),
    categoria: 'Relatório',
    macros: ['projecoes-agro'],
    tipo: 'pdf',
    anos: ['2020', '2021'],
    parceiros: ['CONAB'],
  },
  {
    title: 'Plano de Negócios 2020-24',
    doc: new URL('../../assets/docs/Plano de Negócios 2020-24.pdf', import.meta.url),
    categoria: 'Planejamento',
    macros: ['projecoes-agro'],
    tipo: 'pdf',
    anos: ['2020', '2021', '2022', '2023', '2024'],
    parceiros: ['EMBRAPA'],
  },
  {
    title: 'VII Plano Diretor Embrapa 2020-30',
    doc: new URL('../../assets/docs/Plano Diretor Embrapa.pdf', import.meta.url),
    categoria: 'Planejamento',
    macros: ['projecoes-agro'],
    tipo: 'pdf',
    anos: ['2020', '2021', '2022', '2023', '2024', '2025', '2026', '2027', '2028', '2029', '2030'],
    parceiros: ['EMBRAPA'],
  },
  {
    title: 'Futuro da Cadeia Produtiva da Carne Bovina',
    doc: new URL('../../assets/docs/Futuro da Cadeia Bovina.pdf', import.meta.url),
    categoria: 'Informativo',
    macros: ['projecoes-agro'],
    tipo: 'pdf',
    anos: ['2040'],
    parceiros: ['MAPA'],
  },
  {
    title: 'Projeções do Agronegócio Brasil - 2020/21 à 2030/31',
    doc: new URL('../../assets/docs/Projeções Agro 20-21 a 30-31.pdf', import.meta.url),
    categoria: 'Informativo',
    macros: ['projecoes-agro'],
    tipo: 'pdf',
    anos: ['2020', '2021', '2022', '2023', '2024', '2025', '2026', '2027', '2028', '2029', '2030', '2031'],
    parceiros: ['MAPA'],
  },
  {
    title: 'Plano Estratégico MAPA 2020 - 2031',
    doc: new URL('../../assets/docs/Plano Estratégico MAPA 2020-2031.pdf', import.meta.url),
    categoria: 'Planejamento',
    macros: ['projecoes-agro'],
    tipo: 'pdf',
    anos: ['2020', '2021', '2022', '2023', '2024', '2025', '2026', '2027', '2028', '2029', '2030', '2031'],
    parceiros: ['MAPA'],
  },
  {
    title: 'A Economia Agropecuária do Brasil',
    doc: new URL('../../assets/docs/A Economia Agropecuária do Brasil.pdf', import.meta.url),
    categoria: 'Livro',
    macros: ['projecoes-agro'],
    tipo: 'pdf',
    anos: [],
    parceiros: ['Editora Baraúna'],
  },
  {
    title: 'Plano Safra 2021/22',
    doc: new URL('../../assets/docs/Plano Safra 2021-22.pdf', import.meta.url),
    categoria: 'Livro',
    macros: ['projecoes-agro'],
    tipo: 'pdf',
    anos: ['2021', '2022'],
    parceiros: ['CONAB'],
  },
  {
    title: 'Orientação Covid-19 Agricultor Familiar',
    doc: new URL('../../assets/docs/Orientação Covid-19 Agricultor Familiar.pdf', import.meta.url),
    categoria: 'Cartilha',
    macros: ['docs-gerais'],
    tipo: 'pdf',
    anos: ['-'],
    parceiros: ['ANATER'],
  },
  {
    title: 'Reflorestamento: viabilização econômica, implantação e comercialização',
    doc: new URL('../../assets/docs/Reflorestamento viabilização econômica implantação e comercialização.pdf', import.meta.url),
    categoria: 'Cartilha',
    macros: ['docs-tecnicos'],
    tipo: 'pdf',
    anos: ['-'],
    parceiros: ['SENAR'],
  },
  {
    title: 'Irrigação: gestão e manejo',
    doc: new URL('../../assets/docs/Irrigação gestão e manejo.pdf', import.meta.url),
    categoria: 'Cartilha',
    macros: ['docs-tecnicos'],
    tipo: 'pdf',
    anos: ['-'],
    parceiros: ['SENAR'],
  },
  {
    title: 'Aquicultura: planejamento e legalização de projetos aquícolas',
    doc: new URL('../../assets/docs/Aquicultura planejamento e legalização de projetos aquícolas.pdf', import.meta.url),
    categoria: 'Cartilha',
    macros: ['docs-tecnicos'],
    tipo: 'pdf',
    anos: ['-'],
    parceiros: ['SENAR'],
  },
  {
    title: 'Pastagens: manejo de pastagens tropicais',
    doc: new URL('../../assets/docs/Pastagens manejo de pastagens tropicais.pdf', import.meta.url),
    categoria: 'Cartilha',
    macros: ['docs-tecnicos'],
    tipo: 'pdf',
    anos: ['-'],
    parceiros: ['SENAR'],
  },
  {
    title: 'Leite: ordenha mecânica de bovinos',
    doc: new URL('../../assets/docs/Leite ordenha mecânica de bovinos.pdf', import.meta.url),
    categoria: 'Cartilha',
    macros: ['docs-tecnicos'],
    tipo: 'pdf',
    anos: ['-'],
    parceiros: ['SENAR'],
  },
  {
    title: 'Leite: ordenha manual de bovinos',
    doc: new URL('../../assets/docs/Leite ordenha manual de bovinos.pdf', import.meta.url),
    categoria: 'Cartilha',
    macros: ['docs-tecnicos'],
    tipo: 'pdf',
    anos: ['-'],
    parceiros: ['SENAR'],
  },
  {
    title: 'Café: manejo de podas, desbrotas, irrigação e nutrição',
    doc: new URL('../../assets/docs/Café manejo de podas, desbrotas, irrigação e nutrição.pdf', import.meta.url),
    categoria: 'Cartilha',
    macros: ['docs-tecnicos'],
    tipo: 'pdf',
    anos: ['-'],
    parceiros: ['SENAR'],
  },
  {
    title: 'Café: formação de lavoura',
    doc: new URL('../../assets/docs/Café formação de lavoura.pdf', import.meta.url),
    categoria: 'Cartilha',
    macros: ['docs-tecnicos'],
    tipo: 'pdf',
    anos: ['-'],
    parceiros: ['SENAR'],
  },
  {
    title: 'Grãos: manejo integrado de pragas (MIP) em soja, milho e sorgo',
    doc: new URL('../../assets/docs/Grãos manejo integrado de pragas MIP em soja, milho e sorgo.pdf', import.meta.url),
    categoria: 'Cartilha',
    macros: ['docs-tecnicos'],
    tipo: 'pdf',
    anos: ['-'],
    parceiros: ['SENAR'],
  },
  {
    title: 'Grãos: armazenamento de milho, soja, feijão e café',
    doc: new URL('../../assets/docs/Grãos armazenamento de milho, soja, feijão e café.pdf', import.meta.url),
    categoria: 'Cartilha',
    macros: ['docs-tecnicos'],
    tipo: 'pdf',
    anos: ['-'],
    parceiros: ['SENAR'],
  },
  {
    title: 'Sistemas Agroflorestais (SAFs): conceitos e práticas para implantação no bioma amazônico',
    doc: new URL('../../assets/docs/Sistemas Agroflorestais SAFs conceitos e práticas para implantação no bioma amazônico.pdf', import.meta.url),
    categoria: 'Cartilha',
    macros: ['docs-tecnicos'],
    tipo: 'pdf',
    anos: ['-'],
    parceiros: ['SENAR'],
  },
  {
    title: 'Boletim Agroclimatológico Mensal',
    doc: 'https://portal.inmet.gov.br/boletinsagro',
    categoria: 'Boletim',
    macros: ['boletins'],
    tipo: 'external',
    anos: ['-'],
    parceiros: ['INMET'],
  },
  {
    title: 'Área Projetos Histórico - Dezembro 2020',
    doc: new URL('../../assets/docs/Área Projetos Histórico - Dezembro 2020.ods', import.meta.url),
    categoria: 'Banco de Dados',
    macros: ['reforma-agraria'],
    tipo: 'excel',
    anos: ['2020'],
    parceiros: ['INCRA'],
  },
  {
    title: 'Assentamento Geral PRA 2020',
    doc: new URL('../../assets/docs/Assentamento Geral PRA 2020.pdf', import.meta.url),
    categoria: 'Banco de Dados',
    macros: ['reforma-agraria'],
    tipo: 'pdf',
    anos: ['2020'],
    parceiros: ['INCRA'],
  },
  {
    title: 'Decretos Desapropriatórios - Dezembro 2019',
    doc: new URL('../../assets/docs/Decretos Desapropriatórios - Dezembro 2019.xls', import.meta.url),
    categoria: 'Banco de Dados',
    macros: ['reforma-agraria'],
    tipo: 'excel',
    anos: ['2019'],
    parceiros: ['INCRA'],
  },
  {
    title: 'Famílias Assentadas Histórico 2020',
    doc: new URL('../../assets/docs/Famílias Assentadas Histórico 2020.ods', import.meta.url),
    categoria: 'Banco de Dados',
    macros: ['reforma-agraria'],
    tipo: 'excel',
    anos: ['2020'],
    parceiros: ['INCRA'],
  },
  {
    title: 'Agricultura Digital 2020',
    doc: new URL('../../assets/docs/Agricultura Digital 2020.pdf', import.meta.url),
    categoria: 'Boletim',
    macros: ['reforma-agraria'],
    tipo: 'pdf',
    anos: ['2020'],
    parceiros: ['EMBRAPA'],
  },
  {
    title: 'Agricultura movida a ciência',
    doc: new URL('../../assets/docs/Agricultura movida a ciência.pdf', import.meta.url),
    categoria: 'Boletim',
    macros: ['reforma-agraria'],
    tipo: 'pdf',
    anos: ['-'],
    parceiros: ['EMBRAPA'],
  },
  {
    title: 'Sistemas Brasileiros de Clasificação de Solos 2018',
    doc: new URL('../../assets/docs/Sistemas Brasileiros de Clasificação de Solos 2018.pdf', import.meta.url),
    categoria: 'Boletim',
    macros: ['reforma-agraria'],
    tipo: 'pdf',
    anos: ['2018'],
    parceiros: ['EMBRAPA'],
  },
  {
    title: 'Boletim informativo CAR 2020',
    doc: new URL('../../assets/docs/Boletim informativo CAR 2020.pdf', import.meta.url),
    categoria: 'Boletim',
    macros: ['reforma-agraria'],
    tipo: 'pdf',
    anos: ['2020'],
    parceiros: ['SFB'],
  },
  {
    title: 'Boletim da sociobiodiversidade - Agosto 2021',
    doc: new URL('../../assets/docs/Boletim da sociobiodiversidade - Agosto 2021.pdf', import.meta.url),
    categoria: 'Boletim',
    macros: ['sustentabilidade'],
    tipo: 'pdf',
    anos: ['2021'],
    parceiros: ['CONAB'],
  },
  {
    title: 'Relatório Gestão Florestas Públicas 2019',
    doc: new URL('../../assets/docs/Relatório Gestão Florestas Públicas 2019.pdf', import.meta.url),
    categoria: 'Relatório',
    macros: ['sustentabilidade'],
    tipo: 'pdf',
    anos: ['2019'],
    parceiros: ['CONAB'],
  },
  {
    title: 'Monilíase do Cacaueiro',
    doc: new URL('../../assets/docs/Monilíase do Cacaueiro.pdf', import.meta.url),
    categoria: 'Informativo',
    macros: ['sustentabilidade'],
    tipo: 'pdf',
    anos: ['2019'],
    parceiros: ['Ceplac'],
  },
  {
    title: 'Cartilha Cacaueiro Catalográfica',
    doc: new URL('../../assets/docs/Cartilha Cacaueiro Catalográfica.pdf', import.meta.url),
    categoria: 'Cartilha',
    macros: ['sustentabilidade'],
    tipo: 'pdf',
    anos: ['-'],
    parceiros: ['Ceplac'],
  },
  {
    title: 'Revista Agrotópica Vol 32 - N1 - 2020',
    doc: new URL('../../assets/docs/Revista Agrotópica Vol 32 - N1 - 2020.pdf', import.meta.url),
    categoria: 'Revista',
    macros: ['sustentabilidade'],
    tipo: 'pdf',
    anos: ['2020'],
    parceiros: ['Ceplac'],
  },
  {
    title: 'Revista Agrotópica Vol 32 - N2 - 2020',
    doc: new URL('../../assets/docs/Revista Agrotópica Vol 32 - N2 - 2020.pdf', import.meta.url),
    categoria: 'Revista',
    macros: ['sustentabilidade'],
    tipo: 'pdf',
    anos: ['2020'],
    parceiros: ['Ceplac'],
  },
  {
    title: 'Revista Agrotópica Vol 32 - N3 - 2020',
    doc: new URL('../../assets/docs/Revista Agrotópica Vol 32 - N3 - 2020.pdf', import.meta.url),
    categoria: 'Revista',
    macros: ['sustentabilidade'],
    tipo: 'pdf',
    anos: ['2020'],
    parceiros: ['Ceplac'],
  },
  {
    title: 'Revista Agrotópica Vol 33 - N1 - 2020',
    doc: new URL('../../assets/docs/Revista Agrotópica Vol 33 - N1 - 2020.pdf', import.meta.url),
    categoria: 'Revista',
    macros: ['sustentabilidade'],
    tipo: 'pdf',
    anos: ['2020'],
    parceiros: ['Ceplac'],
  },
  {
    title: 'Guia Metodológico ATER Mais Gestão',
    doc: new URL('../../assets/docs/Guia Metodológico ATER Mais Gestão.pdf', import.meta.url),
    categoria: 'Guia',
    macros: ['sustentabilidade'],
    tipo: 'pdf',
    anos: ['-'],
    parceiros: ['ANATER'],
  },
  {
    title: 'Mulheres nos Projetos ANATER',
    doc: new URL('../../assets/docs/Mulheres nos Projetos ANATER.pdf', import.meta.url),
    categoria: 'Informativo',
    macros: ['sustentabilidade'],
    tipo: 'pdf',
    anos: ['-'],
    parceiros: ['ANATER'],
  },
  {
    title: 'Plano Safra e Sistemas Produtivos Ambientalmente Sustentáveis',
    doc: new URL('../../assets/docs/Plano Safra e Sistemas Produtivos Ambientalmente Sustentáveis.pdf', import.meta.url),
    categoria: 'Informativo',
    macros: ['sustentabilidade'],
    tipo: 'pdf',
    anos: ['-'],
    parceiros: ['CONAB'],
  },
  {
    title: 'Fechamento da Safra de Grãos 2020/21',
    order: 6,
    doc: new URL('../../assets/docs/Fechamento da Safra de Grãos 2020-21.pdf', import.meta.url),
    categoria: 'Boletim',
    macros: ['prod-agricola'],
    tipo: 'pdf',
    anos: ['2020', '2021'],
    parceiros: ['CONAB'],
  },
  {
    title: 'Desempenho do Crédito Rural Série Histórica',
    order: 8,
    doc: 'https://www.gov.br/agricultura/pt-br/assuntos/politica-agricola/credito-rural/desempenho-do-credito-rural-safras-anteriores',
    categoria: 'Pasta',
    macros: ['prod-agricola'],
    tipo: 'external',
    anos: ['-'],
    parceiros: ['MAPA'],
  },
  {
    title: 'Acompanhamento da Safra Brasileira de Grãos 2021/2022 - 9º Levantamento JUNHO 2022',
    order: 1,
    doc: new URL('../../assets/docs/Acompanhamento da Safra Brasileira de Grãos 21-22.pdf', import.meta.url),
    categoria: 'Boletim',
    macros: ['prod-agricola'],
    tipo: 'pdf',
    anos: ['2022'],
    parceiros: ['CONAB'],
  },
  {
    title: 'Acompanhamento da Safra Brasileira de Cana-de-Açucar 2022/2023 – 1º Levantamento ABRIL 2022',
    order: 3,
    doc: new URL('../../assets/docs/Acompanhamento da Safra Brasileira de Cana-de-Açucar 22-23 – 1º Levantamento ABRIL 2022.pdf', import.meta.url),
    categoria: 'Boletim',
    macros: ['prod-agricola'],
    tipo: 'pdf',
    anos: ['2022', '2023'],
    parceiros: ['CONAB'],
  },
  {
    title: 'Crédito Rural - 2020/21',
    order: 7,
    doc: 'https://www.gov.br/agricultura/pt-br/assuntos/politica-agricola/credito-rural/infografico-credito-rural',
    categoria: 'Infográfico',
    macros: ['prod-agricola'],
    tipo: 'external',
    anos: ['2020', '2021'],
    parceiros: ['MAPA'],
  },
  {
    title: 'Boletim de Monitoramento Agrícola – Cultivos de Verão – Safra 2021/22 MAIO 2022',
    order: 4,
    doc: new URL('../../assets/docs/Boletim de Monitoramento Agrícola – Cultivos de Verão – Safra 21-22 MAIO 2022.pdf', import.meta.url),
    categoria: 'Boletim',
    macros: ['prod-agricola'],
    tipo: 'pdf',
    anos: ['2021', '2022'],
    parceiros: ['CONAB'],
  },
  {
    title: 'Boletim Hortigranjeiro Junho 2022',
    order: 9,
    doc: new URL('../../assets/docs/Boletim Hortigranjeiro - Junho 2022.pdf', import.meta.url),
    categoria: 'Boletim',
    macros: ['prod-agricola'],
    tipo: 'pdf',
    anos: ['2022'],
    parceiros: ['CONAB'],
  },
  {
    title: 'Progresso da Safra',
    order: 5,
    doc: 'https://www.conab.gov.br/info-agro/safras/progresso-de-safra',
    categoria: 'Boletim',
    macros: ['prod-agricola'],
    tipo: 'external',
    anos: ['-'],
    parceiros: ['CONAB'],
  },
  {
    title: 'Embrapa em Números',
    order: 22,
    doc: new URL('../../assets/docs/Embrapa em Números.pdf', import.meta.url),
    categoria: 'Boletim',
    macros: ['prod-agricola'],
    tipo: 'external',
    anos: ['-'],
    parceiros: ['EMBRAPA'],
  },
  {
    title: 'Regularização Fundiária Cenário e Legislação',
    order: 21,
    doc: new URL('../../assets/docs/Regularização Fundiária Cenário e Legislação.pdf', import.meta.url),
    categoria: 'Informativo',
    macros: ['prod-agricola'],
    tipo: 'external',
    anos: ['-'],
    parceiros: ['ANATER'],
  },
  {
    title: 'Mapa de Valor Bruto da Produção VBP 2017/21',
    order: 10,
    doc: new URL('../../assets/docs/Mapa de Valor Bruto da Produção VBP 2017-21.xlsx', import.meta.url),
    categoria: 'Relatório',
    macros: ['prod-agricola'],
    tipo: 'excel',
    anos: ['2017', '2018', '2019', '2020', '2021'],
    parceiros: ['MAPA'],
  },
  {
    title: 'Revista Política Agrícola - 2022',
    order: 23,
    doc: 'https://www.gov.br/agricultura/pt-br/assuntos/politica-agricola/todas-publicacoes-de-politica-agricola/revista-de-politica-agricola/2022-1',
    categoria: 'Revista',
    macros: ['prod-agricola'],
    tipo: 'external',
    anos: ['2022'],
    parceiros: ['MAPA'],
  },
  {
    title: 'Estatística e Dados Básicos de Economia Agrícola',
    doc: 'https://www.gov.br/agricultura/pt-br/assuntos/politica-agricola/todas-publicacoes-de-politica-agricola/estatisticas-e-dados-basicos-de-economia-agricola',
    categoria: 'Pasta',
    macros: ['prod-agricola'],
    tipo: 'external',
    anos: ['-'],
    parceiros: ['MAPA'],
  },
  {
    title: 'Algodão',
    order: 11,
    doc: 'https://www.gov.br/agricultura/pt-br/assuntos/politica-agricola/todas-publicacoes-de-politica-agricola/sumarios-executivos-de-produtos-agricolas/algodao-pdf',
    categoria: 'Sumário Executivo',
    macros: ['prod-agricola'],
    tipo: 'pdf',
    anos: ['2022'],
    parceiros: ['MAPA'],
  },
  {
    title: 'Arroz',
    order: 12,
    doc: 'https://www.gov.br/agricultura/pt-br/assuntos/politica-agricola/todas-publicacoes-de-politica-agricola/sumarios-executivos-de-produtos-agricolas/arroz-pdf',
    categoria: 'Sumário Executivo',
    macros: ['prod-agricola'],
    tipo: 'pdf',
    anos: ['2022'],
    parceiros: ['MAPA'],
  },
  {
    title: 'Café',
    order: 13,
    doc: 'https://www.gov.br/agricultura/pt-br/assuntos/politica-agricola/todas-publicacoes-de-politica-agricola/sumarios-executivos-de-produtos-agricolas/cafe-pdf',
    categoria: 'Sumário Executivo',
    macros: ['prod-agricola'],
    tipo: 'pdf',
    anos: ['2022'],
    parceiros: ['MAPA'],
  },
  {
    title: 'Carnes',
    order: 14,
    doc: 'https://www.gov.br/agricultura/pt-br/assuntos/politica-agricola/todas-publicacoes-de-politica-agricola/sumarios-executivos-de-produtos-agricolas/carnes-pdf',
    categoria: 'Sumário Executivo',
    macros: ['prod-agricola'],
    tipo: 'pdf',
    anos: ['2022'],
    parceiros: ['MAPA'],
  },
  {
    title: 'Feijão',
    order: 15,
    doc: 'https://www.gov.br/agricultura/pt-br/assuntos/politica-agricola/todas-publicacoes-de-politica-agricola/sumarios-executivos-de-produtos-agricolas/feijao-pdf',
    categoria: 'Sumário Executivo',
    macros: ['prod-agricola'],
    tipo: 'pdf',
    anos: ['2022'],
    parceiros: ['MAPA'],
  },
  {
    title: 'Laranja',
    order: 16,
    doc: 'https://www.gov.br/agricultura/pt-br/assuntos/politica-agricola/todas-publicacoes-de-politica-agricola/sumarios-executivos-de-produtos-agricolas/laranja-pdf',
    categoria: 'Sumário Executivo',
    macros: ['prod-agricola'],
    tipo: 'pdf',
    anos: ['2022'],
    parceiros: ['MAPA'],
  },
  {
    title: 'Leite',
    order: 17,
    doc: 'https://www.gov.br/agricultura/pt-br/assuntos/politica-agricola/todas-publicacoes-de-politica-agricola/sumarios-executivos-de-produtos-agricolas/leite-pdf',
    categoria: 'Sumário Executivo',
    macros: ['prod-agricola'],
    tipo: 'pdf',
    anos: ['2022'],
    parceiros: ['MAPA'],
  },
  {
    title: 'Milho',
    order: 18,
    doc: 'https://www.gov.br/agricultura/pt-br/assuntos/politica-agricola/todas-publicacoes-de-politica-agricola/sumarios-executivos-de-produtos-agricolas/milho-pdf',
    categoria: 'Sumário Executivo',
    macros: ['prod-agricola'],
    tipo: 'pdf',
    anos: ['2022'],
    parceiros: ['MAPA'],
  },
  {
    title: 'Soja',
    order: 19,
    doc: 'https://www.gov.br/agricultura/pt-br/assuntos/politica-agricola/todas-publicacoes-de-politica-agricola/sumarios-executivos-de-produtos-agricolas/soja-pdf',
    categoria: 'Sumário Executivo',
    macros: ['prod-agricola'],
    tipo: 'pdf',
    anos: ['2022'],
    parceiros: ['MAPA'],
  },
  {
    title: 'Trigo',
    order: 20,
    doc: 'https://www.gov.br/agricultura/pt-br/assuntos/politica-agricola/todas-publicacoes-de-politica-agricola/sumarios-executivos-de-produtos-agricolas/trigo-pdf',
    categoria: 'Sumário Executivo',
    macros: ['prod-agricola'],
    tipo: 'pdf',
    anos: ['2022'],
    parceiros: ['MAPA'],
  },
  {
    title: 'Acompanhamento da Safra Brasileira de Café 2022 – 2º Levantamento MAIO 2022',
    order: 2,
    doc: new URL('../../assets/docs/Acompanhamento da Safra Brasileira de Café 2022 – 2º Levantamento MAIO 2022.pdf', import.meta.url),
    categoria: 'Boletim',
    macros: ['prod-agricola'],
    tipo: 'pdf',
    anos: ['2022'],
    parceiros: ['CONAB'],
  },
]
