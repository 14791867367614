/*
  Ao adicionar um painel novo, lembrar de manter sempre a propriedade TITLE
  com o nome do painel em português, mesmo contendo uma key para tradução.

  O TITLE serve para a ordenação dos paineis na tela.
*/

export const panels = [
  {
    title: 'Agricultura Familiar',
    translation: 'familyFarming',
    key: 'agricultura-familiar',
    linkStatistic: '/agriculturaFamiliar',
    keyGeo: 'AGRICULTURA_FAMILIAR',
    envs: ['development', 'homolog', 'production'],
    miniLogo: new URL('../../assets/imgs/mini_agricultura_familiar.png', import.meta.url),
    fullLogo: new URL('../../assets/imgs/full_agricultura_familiar.png', import.meta.url),
    svgIcon: new URL('../../assets/svgs/metadados_agricultura_familiar.svg', import.meta.url),
    link: false,
    metadataStatistical: [
      {
        title: 'DAP - Declaração de Aptidão ao Pronaf',
        fonts: ['MAPA'],
        update: 'AGRICULTURA FAMILIAR:DAP',
      },
      {
        title: 'PAA - Programa de Aquisição de Alimentos',
        fonts: ['CONAB'],
        update: 'AGRICULTURA FAMILIAR:PAA',
      },
    ],
  },
  {
    title: 'Agropecuária Sustentável e Meio Ambiente',
    translation: 'agroSus',
    shortTitle: 'Agro. Sust. e Meio Amb.',
    key: 'agropecuaria-sustentavel',
    keyGeo: 'AGROPECUARIA_SUSTENTAVEL',
    envs: ['development', 'homolog', 'production'],
    miniLogo: new URL('../../assets/imgs/mini_agro_sustentavel.png', import.meta.url),
    fullLogo: new URL('../../assets/imgs/full_agro_sustentavel.png', import.meta.url),
    svgIcon: new URL('../../assets/svgs/metadados_agropecuaria.svg', import.meta.url),
    link: false,
  },
  {
    title: 'Aquicultura',
    translation: 'aquaculture',
    key: 'aquicultura',
    linkStatistic: '/aquicultura',
    keyGeo: 'AQUICULTURA_E_PESCA',
    envs: ['development', 'homolog', 'production'],
    miniLogo: new URL('../../assets/imgs/mini_aquicultura.png', import.meta.url),
    fullLogo: new URL('../../assets/imgs/full_aquicultura.png', import.meta.url),
    svgIcon: new URL('../../assets/svgs/metadados_aquicultura.svg', import.meta.url),
    link: false,
    metadataStatistical: [
      {
        title: 'Visão Geral',
        fonts: ['IBGE'],
        update: 'AQUICULTURA',
      },
    ],
  },
  {
    title: 'Assistência Técnica',
    translation: 'tech',
    key: 'assistencia-tecnica',
    keyGeo: 'ASSISTENCIA_TECNICA',
    envs: ['development', 'homolog', 'production'],
    miniLogo: new URL('../../assets/imgs/mini_assistencia_tecnica.png', import.meta.url),
    fullLogo: new URL('../../assets/imgs/full_assistencia_tecnica.png', import.meta.url),
    svgIcon: new URL('../../assets/svgs/metadados_assistencia_tecnica.svg', import.meta.url),
    link: false,
  },
  {
    title: 'Assuntos Fundiários',
    translation: 'fundiary',
    key: 'assuntos-fundiarios',
    keyGeo: 'ASSUNTOS_FUNDIARIOS',
    envs: ['development', 'homolog', 'production'],
    linkStatistic: '/assuntosFundiarios',
    miniLogo: new URL('../../assets/imgs/mini_assuntos_fundiarios.png', import.meta.url),
    fullLogo: new URL('../../assets/imgs/full_assuntos_fundiarios.png', import.meta.url),
    svgIcon: new URL('../../assets/svgs/metadados_assuntos_fundiarios.svg', import.meta.url),
    link: false,
    metadataStatistical: [
      {
        title: 'Glebas Públicas Federais',
        fonts: ['INCRA'],
        update: 'ASSUNTOS FUNDIÁRIOS:GLEBAS',
      },
      {
        title: 'Titula Brasil',
        fonts: ['INCRA'],
        update: 'ASSUNTOS FUNDIÁRIOS:TITULA BRASIL',
      },
      {
        title: 'Assentamentos',
        fonts: ['INCRA'],
        update: 'ASSUNTOS FUNDIÁRIOS:ASSENTAMENTOS',
      },
    ],
  },
  {
    title: 'Crédito Rural',
    translation: 'credit',
    key: 'credito-rural',
    new: true,
    linkStatistic: '/creditoRural',
    keyGeo: 'CREDITO_RURAL',
    envs: ['development', 'homolog', 'production'],
    miniLogo: new URL('../../assets/imgs/mini_credito_rural.png', import.meta.url),
    fullLogo: new URL('../../assets/imgs/full_credito_rural.png', import.meta.url),
    svgIcon: new URL('../../assets/svgs/metadados_credito_rural.svg', import.meta.url),
    link: false,
    metadataStatistical: [],
  },
  {
    title: 'Fertilizantes',
    translation: 'fertilizers',
    key: 'fertilizantes',
    linkStatistic: '/fertilizantes',
    envs: ['development', 'homolog', 'production'],
    miniLogo: new URL('../../assets/imgs/mini_fertilizantes.png', import.meta.url),
    fullLogo: new URL('../../assets/imgs/full_fertilizantes.png', import.meta.url),
    svgIcon: new URL('../../assets/svgs/metadados_fertilizantes.svg', import.meta.url),
    link: false,
    metadataStatistical: [
      {
        title: 'Mercado doméstico',
        fonts: ['Comex Stat', 'MAPA'],
        update: 'FERTILIZANTES',
      },
      {
        title: 'Importações',
        fonts: ['Comex Stat', 'MAPA'],
        update: 'FERTILIZANTES',
        frequency: 'Esporádico',
      },
      {
        title: 'Fornecedores',
        fonts: ['MRE', 'FAOSTAT'],
        update: 'FERTILIZANTES',
        frequency: 'Esporádico',
      },
    ],
  },
  {
    title: 'Produtos Agrícolas',
    translation: 'prod',
    key: 'produtos-agricolas',
    linkStatistic: '/prodAgricola',
    keyGeo: 'PRODUTOS_AGRICOLAS',
    envs: ['development', 'homolog', 'production'],
    miniLogo: new URL('../../assets/imgs/mini_prod_agricola.png', import.meta.url),
    fullLogo: new URL('../../assets/imgs/full_prod_agricola.png', import.meta.url),
    svgIcon: new URL('../../assets/svgs/metadados_produtos_agricolas.svg', import.meta.url),
    link: false,
    metadataStatistical: [
      {
        title: 'Visão Geral',
        fonts: ['CONAB'],
        update: 'PRODUTOS AGRÍCOLAS',
      },
    ],
  },
  {
    title: 'Programa Nacional de Solos',
    translation: 'pronasolos',
    shortTitle: 'PronaSolos',
    key: 'pronasolos',
    linkStatistic: '/pronasolos',
    envs: ['development', 'homolog', 'production'],
    miniLogo: new URL('../../assets/imgs/mini_pronasolos.png', import.meta.url),
    fullLogo: new URL('../../assets/imgs/full_pronasolos.png', import.meta.url),
    svgIcon: new URL('../../assets/svgs/metadados_pronasolos.svg', import.meta.url),
    link: false,
    metadataStatistical: [
      {
        title: 'Visão Geral',
        fonts: ['IBGE', 'Embrapa'],
        update: 'PRONASOLOS',
      },
    ],
  },
  {
    title: 'Zoneamento Agrícola de Risco Climático',
    translation: 'zarc',
    shortTitle: 'ZARC',
    key: 'zarc',
    linkStatistic: '/zarc',
    keyGeo: 'ZARC',
    envs: ['development', 'homolog', 'production'],
    miniLogo: new URL('../../assets/imgs/mini_zarc.png', import.meta.url),
    fullLogo: new URL('../../assets/imgs/full_zarc.png', import.meta.url),
    svgIcon: new URL('../../assets/svgs/metadados_zarc.svg', import.meta.url),
    link: false,
    metadataStatistical: [
      {
        title: 'Visão Geral',
        fonts: ['IBGE', 'Embrapa'],
        update: 'ZARC',
      },
    ],
  },
  {
    title: 'Sistema Brasileiro de Inspeção',
    translation: 'sisbi',
    shortTitle: 'SISBI',
    key: 'sisbi',
    linkStatistic: '/sisbi',
    envs: ['development', 'homolog', 'production'],
    miniLogo: new URL('../../assets/imgs/mini_sisbi.png', import.meta.url),
    fullLogo: new URL('../../assets/imgs/full_sisbi.png', import.meta.url),
    svgIcon: new URL('../../assets/svgs/metadados_sisbi.svg', import.meta.url),
    link: false,
    metadataStatistical: [
      {
        title: 'Visão Geral',
        fonts: ['IBGE', 'Embrapa'],
        update: 'SISBI',
      },
    ],
  },
  {
    title: 'Comércio Exterior',
    translation: 'comex',
    key: 'comex',
    linkStatistic: '/comercioExterior',
    envs: ['development', 'homolog', 'production'],
    miniLogo: new URL('../../assets/imgs/mini_comex.png', import.meta.url),
    fullLogo: new URL('../../assets/imgs/full_comex.png', import.meta.url),
    svgIcon: new URL('../../assets/svgs/metadados_comex.svg', import.meta.url),
    link: false,
  },
  {
    title: 'Pecuária',
    translation: 'pecuary',
    key: 'pecuaria',
    linkStatistic: '/pecuaria',
    keyGeo: 'PECUARIA',
    envs: ['development', 'homolog', 'production'],
    miniLogo: new URL('../../assets/imgs/mini_pecuaria.png', import.meta.url),
    fullLogo: new URL('../../assets/imgs/full_pecuaria.png', import.meta.url),
    svgIcon: new URL('../../assets/svgs/metadados_pecuaria.svg', import.meta.url),
    link: false,
  },
  {
    title: 'Recursos Hídricos e Agricultura',
    translation: 'hydricResources',
    key: 'hidrografia',
    linkStatistic: '',
    keyGeo: 'HIDROGRAFIA',
    envs: ['development', 'homolog', 'production'],
    miniLogo: new URL('../../assets/imgs/mini_hidrico.png', import.meta.url),
    fullLogo: new URL('../../assets/imgs/full_hidrico.png', import.meta.url),
    svgIcon: new URL('../../assets/svgs/metadados_pecuaria.svg', import.meta.url),
    link: true,
  },
  {
    title: 'Indicações Geograficas',
    translation: 'geographicIndications',
    key: 'painel_geral',
    linkStatistic: '',
    keyGeo: 'PAINEL_GERAL',
    envs: ['development', 'homolog', 'production'],
    miniLogo: new URL('../../assets/imgs/mini_indicacoes_geograficas.png', import.meta.url),
    fullLogo: new URL('../../assets/imgs/full_indicacoes_geograficas.png', import.meta.url),
    svgIcon: new URL('../../assets/svgs/metadados_pecuaria.svg', import.meta.url),
    link: false,
  },
  {
    title: 'Destaques do Censo Agropecuário',
    shortTitle: 'Destaques do Censo Agro.',
    translation: 'agroCensus',
    key: 'censo',
    linkStatistic: null,
    keyGeo: 'CENSO_AGROPECUARIO',
    envs: ['development', 'homolog', 'production'],
    miniLogo: new URL('../../assets/imgs/mini_censo.png', import.meta.url),
    fullLogo: new URL('../../assets/imgs/full_censo.png', import.meta.url),
    svgIcon: new URL('../../assets/svgs/metadados_pecuaria.svg', import.meta.url),
    link: false,
  },
  {
    title: 'Florestas',
    translation: 'forests',
    new: true,
    key: 'forests',
    linkStatistic: '/florestas',
    keyGeo: 'FLORESTAS',
    envs: ['development', 'homolog'],
    miniLogo: new URL('../../assets/imgs/mini_florestas.png', import.meta.url),
    fullLogo: new URL('../../assets/imgs/full_florestas.png', import.meta.url),
    svgIcon: new URL('../../assets/svgs/metadados_pecuaria.svg', import.meta.url),
    link: false,
  },
  {
    title: 'Agropecuária e Clima',
    translation: 'climate',
    new: true,
    key: 'agroclima',
    linkStatistic: '/agroclima',
    keyGeo: null,
    envs: ['development', 'homolog'],
    miniLogo: new URL('../../assets/imgs/mini_agro_sustentavel.png', import.meta.url),
    fullLogo: new URL('../../assets/imgs/full_agro_sustentavel.png', import.meta.url),
    svgIcon: new URL('../../assets/svgs/metadados_pecuaria.svg', import.meta.url),
    link: false,
  },
]
