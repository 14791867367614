import { panels } from '~/src/scripts/data/panels'
import { projects } from '~/src/scripts/data/projects'
import { partners } from '~/src/scripts/data/partners'
import { macros, documents } from '~/src/scripts/data/libraryData'
import { geoTodas_PROD } from '~/src/scripts/data/metadados'

function SortByProp(group, property) {
  return group.sort(function (a, b) {
    var textA = a[property].toString().toUpperCase()
    var textB = b[property].toString().toUpperCase()
    return textA < textB ? -1 : textA > textB ? 1 : 0
  })
}

function UniqueByProp(original, exceptions, property) {
  return original.filter(function (el) {
    return exceptions.findIndex((ex) => ex[property] === el[property]) < 0
  })
}

function SetUpConfigs() {
  window.sortByProp = SortByProp
  window.uniqueByProp = UniqueByProp

  window.partners = partners
  window.libMacros = macros
  window.libDocs = documents

  window.geoTodas = geoTodas_PROD
  window.env = process.env.TARGET

  window.panels = panels.filter((panel) => panel.envs.includes(window.env))
  window.projects = projects

  if (process.env.TARGET === 'development') {
    window.statisticalLink = 'http://squads.geotech4web.com.br/atena/observatorio'
    window.geoLink = 'https://squads.geotech4web.com.br/toth/plataforma-geoespacial/'
    window.reportsLink = 'http://teste.observatorio.gt4w.com.br/relatorio-dinamico/relatorio'
    window.metadataGeoLink = 'https://squads.geotech4web.com.br/toth/plataforma-geoespacial/api/v1/categorias/todas/'
    window.contactFormLink = 'http://squads.geotech4web.com.br/atena/observatorio/portal/newsletter/signup'
  } else if (process.env.TARGET === 'homolog') {
    window.statisticalLink = 'https://homolog.agropecuaria.inmet.gov.br/paineis'
    window.geoLink = 'https://homolog.agropecuaria.inmet.gov.br/plataforma-geoespacial/'
    window.reportsLink = 'https://homolog.agropecuaria.inmet.gov.br/relatorio-dinamico/'
    window.metadataGeoLink = 'https://homolog.agropecuaria.inmet.gov.br/plataforma-geoespacial/api/v1/categorias/todas/'
    window.contactFormLink = 'https://homolog.agropecuaria.inmet.gov.br/paineis/portal/newsletter/signup'
  } else {
    window.statisticalLink = 'https://observatorio.agropecuaria.inmet.gov.br/paineis'
    window.geoLink = 'https://observatorio.agropecuaria.inmet.gov.br/plataforma-geoespacial/'
    window.reportsLink = 'https://observatorio.agropecuaria.inmet.gov.br/relatorio-dinamico/'
    window.metadataGeoLink = 'https://observatorio.agropecuaria.inmet.gov.br/plataforma-geoespacial/api/v1/categorias/todas/'
    window.contactFormLink = 'https://observatorio.agropecuaria.inmet.gov.br/paineis/portal/newsletter/signup'
  }
}

export default SetUpConfigs
