export const partners = [
  {
    name: 'ANATER',
    translation: 'anater',
    key: 'anater',
    website: 'https://www.anater.org/',
    logo: new URL('../../assets/imgs/parceiro-anater.png', import.meta.url)
  },
  {
    name: 'Banco Central do Brasil',
    translation: 'bank',
    key: 'banco-brasil',
    website: 'https://www.bcb.gov.br/?bc=',
    logo: new URL('../../assets/imgs/parceiro-banco-brasil.png', import.meta.url)
  },
  {
    name: 'CNA',
    translation: 'cna',
    key: 'cna',
    website: 'https://www.cnabrasil.org.br/',
    logo: new URL('../../assets/imgs/parceiro-cna.png', import.meta.url)
  },
  {
    name: 'CONAB',
    translation: 'conab',
    key: 'conab',
    website: 'https://www.conab.gov.br/',
    logo: new URL('../../assets/imgs/parceiro-conab.png', import.meta.url)
  },
  {
    name: 'Embrapa',
    translation: 'embrapa',
    key: 'embrapa',
    website: 'https://www.embrapa.br/',
    logo: new URL('../../assets/imgs/parceiro-embrapa.png', import.meta.url),
  },
  {
    name: 'IBGE',
    translation: 'ibge',
    key: 'ibge',
    website: 'https://www.ibge.gov.br/',
    logo: new URL('../../assets/imgs/parceiro-ibge.png', import.meta.url)
  },
  {
    name: 'INCRA',
    translation: 'incra',
    key: 'incra',
    website: 'https://www.gov.br/incra/pt-br',
    logo: new URL('../../assets/imgs/parceiro-incra.png', import.meta.url)
  },
  {
    name: 'INMET',
    translation: 'inmet',
    key: 'inmet',
    website: 'https://portal.inmet.gov.br/',
    logo: new URL('../../assets/imgs/parceiro-inmet.png', import.meta.url)
  },
  {
    name: 'LAPIG',
    translation: 'lapig',
    key: 'lapig',
    website: 'https://lapig.iesa.ufg.br/',
    logo: new URL('../../assets/imgs/parceiro-lapig.png', import.meta.url)
  },
  {
    name: 'Observarei',
    translation: 'observarei',
    key: 'observarei',
    website: 'https://www.observarei.com/',
    logo: new URL('../../assets/imgs/parceiro-observarei.png', import.meta.url)
  },
  {
    name: 'Serviço Florestal Brasileiro',
    translation: 'sfb',
    key: 'sfb',
    website: 'https://www.florestal.gov.br/',
    logo: new URL('../../assets/imgs/parceiro-sfb.png', import.meta.url)
  }
]