const ptBR = {
  commons: {
    access: 'Acesse',
    soon: 'Em breve',
    statistical: 'Estatística',
    statisticalPlatform: 'Plataforma Estatística',
    statisticalCta: 'Encontre informações agropecuárias em números, tabelas e gráficos:',
    statisticalAccess: 'Acessar plataforma estatística',
    geo: 'Geoespacial',
    geoPlatform: 'Plataforma Geoespacial',
    geoCta: 'Descubra novas formas de cruzar informações agropecuárias por meio de mapas:',
    geoAccess: 'Acessar plataforma geoespacial',
    reports: 'Relatórios',
    reportsPlatform: 'Plataforma de Relatórios',
    platforms: 'Plataformas',
    thematicPanels: 'Painéis Temáticos',
    new: 'novo!',
    font: 'Fonte',
    start: 'Início',
    institutional: 'Institucional',
    partners: 'Parceiros',
    library: 'Biblioteca',
    metadata: 'Metadados',
    allPanels: 'Todos os painéis',
    observatory: 'Observatório',
    billions: 'bilhões',
    billionsStar: 'bilhões*',
    trillions: 'trilhões',
  },
  panels: {
    comex: {
      title: 'Comércio Exterior',
      description:
        'O painel do comércio exterior destaca a participação dos produtos brasileiros no fluxo internacional do agronegócio, um grande gerador de riquezas para o país. A versão traz, por meio de representações numéricas, gráficas, tabelas e de mapas da Plataforma Estatístico, informações de volume e valor das importações e exportações, os principais parceiros comerciais, o desempenho por estado e os locais de entrada e saída das mercadorias, extraídos dos sistemas Agrostat e Siscomex/Comex Stat. Os filtros disponíveis possibilitam consultas bastante flexíveis, complementada com a visualização espacial dos países importadores/exportadores e localização das unidades da Receita Federal no país, por onde as mercadorias saem/entram, estampando a dimensão do comércio exterior brasileiro.',
    },
    pecuary: {
      title: 'Pecuária',
      description: `O Painel Temático da Pecuária reúne os dados de um dos setores produtivos mais importantes do nosso país, possibilitando o acesso às informações gerais e específicas de cada sistema de produção da pecuária brasileira.	

Auxiliados pelas fontes de dados da Companhia Nacional de Abastecimento – Conab, do Instituto Brasileiro de Geografia e Estatística - IBGE, da Empresa Brasileira de Pesquisa Agropecuária – Embrapa e do Laboratório de Processamento de Imagens e Geoprocessamento da Universidade Federal de Goiás – Lapig/UFG, são disponibilizadas informações que auxiliam no conhecimento da cadeia produtiva desse setor. 
O Painel Temático da Pecuária apresenta dados classificados por período, região, unidade federativa e município. Além disso, também é possível identificar os tipos de produção animal, as atividades predominantes, quantitativos de rebanho e abate, entre outros.
`,
    },
    sisbi: {
      title: 'Sistema Brasileiro de Inspeção',
      shortTitle: 'SISBI',
      description:
        'O Painel do SISBI dispõe, em sua primeira versão, de informações sobre os serviços de inspeção de produtos de origem animal estaduais, distrital e municipais, individual ou vinculado a consórcios públicos de municípios, bem como dos estabelecimentos e produtos registrados nesses serviços e cadastrados por eles no sistema eletrônico e-SISBI. No Painel, o usuário poderá obter essas informações públicas por meio de gráficos, mapas ou planilhas, construídos com base nos cadastros efetuados pelos referidos serviços de inspeção e estabelecimentos, e pelo próprio MAPA. As informações podem ser úteis para os serviços de inspeção oficiais e demais órgãos fiscalizadores, serviços de extensão rural, certificadores, empreendedores, educadores, pesquisadores e qualquer cidadão interessado. Com isso, o MAPA busca contribuir cada vez mais com a gestão e maior transparência no âmbito do SISBI.',
    },
    familyFarming: {
      title: 'Agricultura Familiar',
      description:
        'O painel dispõe, por meio das visões geoespacial e estatística, de informações de apoio às estratégias de inclusão social e produtiva para a geração de renda de agricultores familiares. Destaca-se nesta versão a disponibilização de gráficos, mapas e relatórios tocantes à Declaração de Aptidão ao Pronaf (Programa Nacional de Fortalecimento da Agricultura Familiar) e ao Programa de Aquisição de Alimentos (PAA), operado pela Companhia Nacional de Abastecimento (Conab).',
    },
    agroSus: {
      title: 'Agropecuária Sustentável e Meio Ambiente',
      shortTitle: 'Agro. Sust. e Meio Amb.',
      description:
        'O painel da Agropecuária Sustentável e Meio Ambiente coloca à disposição, através da Plataforma Geoespacial, informações consolidadas de irrigação, projetos de agropecuária sustentável, regularização ambiental, entre outros temas, de diversas fontes e bases de dados. Extraídas por meio de cruzamentos espaciais e tabulares, as informações são apresentadas tanto espacialmente, quanto no formato de relatórios sucintos, com números e gráficos.',
    },
    aquaculture: {
      title: 'Aquicultura',
      description:
        'O painel temático da aquicultura apresenta informações da produção de peixes, camarões e moluscos, alevinos, larvas de camarão e sementes de moluscos, bem como o valor da produção desses e de outros animais (rã, jacaré etc.). São informações obtidas da Produção da Pecuária Municipal (PPM), levantamento realizado pelo IBGE. A plataforma tem por objetivo permitir o direcionamento das ações e políticas públicas para o desenvolvimento da cadeia aquícola, proporcionando transparência e possibilitando novos investimentos, com a disponibilização das informações aquícolas de forma ágil e fácil aos usuários/interessados.',
    },
    tech: {
      title: 'Assistência Técnica',
      description:
        'O Painel da Assistência Técnica do Observatório da Agropecuária Brasileira dispõe, por meio da Plataforma Geoespacial, de dados da assistência técnica prestada no território nacional. Destaca-se nesta versão a disponibilização de mapas, gráficos e relatórios gerados a partir do cruzamento de importantes bases de dados do setor, compondo uma plataforma dinâmica sobre a distribuição dos atendimentos prestados, assim como as atividades principais assistidas, resultando em um conjunto inédito de informações deste importante segmento.',
    },
    fundiary: {
      title: 'Assuntos Fundiários',
      description:
        'O Painel de Assuntos Fundiários tem como propósito dispor de informações claras acerca da temática fundiária no território nacional, por meio das Plataformas Geoespacial e Estatística. A Geospacial possibilita a visualização de camadas de imóveis certificados públicos e privados, áreas embargadas e informações do Cadastro Ambiental Rural (CAR), permitindo o cruzamento de dados e emissão de relatórios. Já na Plataforma Estatística, é possível obter informações sobre a titulação em glebas públicas federais e em assentamentos do Instituto Nacional de Colonização e Reforma Agrária (Incra). Os filtros aplicados no painel oferecem a visualização por período, região, unidade federativa e município (verificar a troca dos municípios pela Superintendência Regional), permitindo uma análise do desempenho titulatório de cada região, bem como viabiliza visão detalhada e personalizada das atividades fundiárias.',
    },
    credit: {
      title: 'Crédito Rural',
      description:
        'Para consulta de informações sobre o crédito rural, este painel temático disponibiliza, de maneira consolidada e interativa, os dados oriundos do Sicor, do Banco Central do Brasil, para quantidade e valor dos contratos em quatro finalidades: custeio, investimento, comercialização e industrialização. Os filtros aplicados no painel oferecem a visualização por período, fonte de recurso, programa, subprograma, produto, atividade, região, estado e município, permitindo a análise do desempenho do crédito rural no Brasil e visão detalhada e personalizada dos dados e informações estruturadas, conforme a necessidade e demanda de cada usuário.',
    },
    fertilizers: {
      title: 'Fertilizantes',
      description:
        'O Painel de Fertilizantes reúne diversas informações do setor em um mesmo ambiente. Por meio da Plataforma Estatística, são integradas as bases de dados compartilhadas pelo MAPA, CONAB, ME, MRE, FAO e ANDA e é disponibilizado ao usuário um ambiente de acesso fácil e rápido, adequado às práticas de usabilidade e experiência do usuário. Nessa primeira versão do painel, estão disponíveis gráficos, tabelas e mapas contendo importantes informações do setor de fertilizantes, como importação, exportação, lista de fornecedores, totalizações, entre outras.',
    },
    prod: {
      title: 'Produtos Agrícolas',
      description: `A nova versão do Painel de Produtos Agrícolas dispõe agora de 18 produtos. Além da inclusão de novas culturas, os dados podem ser visualizados por safra, por tipo e também traz os resultados do valor bruto da produção dos últimos 10 anos.
      O painel se propõe a apresentar em um único ambiente informações sobre crédito, cultivo, custos, disponibilidades e mercado interno e externo de importantes culturas agrícolas brasileiras. Complementadas com dados de zoneamento agrícola e de clima, propicia uma ampla visão para o governo, produtores, cooperativas, pesquisadores, tomadores de decisão e demais usuários. 
      Além do MAPA, as informações integradas têm por fonte a Conab, o Banco Central do Brasil, o IBGE e o Ministério da Economia.
        `,
    },
    pronasolos: {
      title: 'Programa Nacional de Solos',
      shortTitle: 'PronaSolos',
      description:
        'Os solos do Brasil estão passando por uma promissora fase de refinamento e melhoria de escala de mapeamento e inventário, bem como de consolidação das pesquisas e bases espaciais já disponíveis, por meio da ampla integração setorial e institucional do PronaSolos. O Painel Temático do PronaSolos apresenta, de maneira interativa, as bases de dados espaciais de solos em nível nacional, atualmente disponíveis no Brasil em escala 1:250.000, permitindo a visualização estatística do percentual de área ocupada no Brasil e em cada Unidade da Federação.',
    },
    zarc: {
      title: 'Zoneamento Agrícola de Risco Climático',
      shortTitle: 'ZARC',
      description:
        'O Painel Temático do ZARC tem como propósito apresentar as informações consolidadas de todas as portarias do zoneamento para cada cultura, grupo, período do ano e tipo de solo, nas Plataformas Estatística e Geoespacial. O painel oferece, ainda, ferramentas integradas, com o intuito de promover maior usabilidade e intuitividade na interpretação da informação, centralizando a tábua de risco, a relação de cultivares e também mapas de visualização dos dados por município, estado e região.',
    },
    geographicIndications: {
      title: 'Indicações Geográficas',
      description: `As Indicações Geográficas se referem a produtos ou serviços que tenham uma origem geográfica específica. Seu registro reconhece reputação, qualidades e características que estão vinculadas ao local. Como resultado, elas comunicam ao mundo que certa região se especializou e tem capacidade de produzir um artigo/prestar um serviço diferenciado e de excelência.
      Na Plataforma Geoespacial disponibilizamos por meio de camadas, a visualização dos lugares associados a produtos ou serviços típicos relacionados, sejam eles Indicações Geográficas (IG) ou Produtos Típicos Potenciais (PTP).
      A Indicação Geográfica se constitui sob duas formas: a Indicação de Procedência e a Denominação de Origem. A Indicação de Procedência é o nome geográfico de país, cidade, região ou localidade de seu território, que se tenha tornado conhecido como centro de extração, produção ou fabricação de determinado produto ou de prestação de determinado serviço. Já a Denominação de Origem é o nome geográfico de país, cidade, região ou localidade de seu território, que designe produto ou serviço cujas qualidades ou características se devam exclusiva ou essencialmente ao meio geográfico, incluídos fatores naturais e humanos.
      Os produtos agropecuários foram destacados por fazerem parte do âmbito de atuação do Ministério, bem como devido à importância social, cultural e econômica para suas regiões.`,
    },
    hydricResources: {
      title: 'Recursos Hídricos e Agricultura',
      description: `O Painel Recursos Hídricos e Agricultura contempla dados e camadas geoespacializadas com ênfase nas informações sobre a Irrigação e sua relevância para a produção agrícola brasileira.
      É possível visualizar as informações sobre a hidrografia (nos seus 7 níveis), área irrigada atual e adicional efetiva e total; Polos Nacionais de Irrigação, Outorgas, Tipologias da Irrigação, dentre outras. Mantendo o padrão de consulta dos diversos temas contidos no acervo do Observatório da Agropecuária Brasileira é dado aos diferentes usuários do setor a possibilidade de fazer a gestão e o cruzamento desse tema com as demais camadas contidas no banco de dados do Portal.
      A fonte utilizada foi o Atlas de Irrigação 2021 disponível na página da Agência Nacional de Águas - ANA. `,
      link: true,
    },
    agroCensus: {
      title: 'Destaques do Censo Agropecuário',
      shortTitle: 'Destaques do Censo Agro.',
      description:
        'O painel do Censo Agropecuário 2017 destaca alguns dos principais dados divulgados pelo Instituto Brasileiro de Geografia e Estatística (IBGE), que retratam a realidade do campo por meio da investigação estatística e territorial sobre a produção agropecuária brasileira. Entre os dados disponibilizados na Plataforma Geoespacial estão o perfil do produtor, sua atividade econômica, os usos da terra e da agricultura familiar, possibilitando a estratificação regional, estadual e municipal. O painel também permite habilitar camadas dos outros temas disponíveis no Observatório, ampliando as possibilidades de cruzamento de informações e de  análise do segmento agropecuário.',
    },
    vertentes: {
      title: 'Vertentes',
      description: `O “Projeto Vertentes” – Consórcios de paisagem de uso múltiplo sustentável - Sistemas alimentares, uso do solo e restauração, financiado pelo Banco Mundial - BIRD está sendo executado pelo Ministério da Agricultura e Pecuária - MAPA, Ministério do Meio Ambiente - MMA e Serviço Nacional de Aprendizagem Rural - SENAR.
O objetivo principal do Projeto é aumentar a área sob gestão sustentável da paisagem e recomposição nas cadeias de valor da pecuária de corte e da soja em paisagens selecionadas no Brasil. 
Os objetivos específicos são: (i) Formar Consórcios Locais com vistas a criar capacidade e conhecimento necessários para apoiar o planejamento, a governança e os principais investimentos da região. (ii) Promover a adoção de práticas sustentáveis de produção de alimentos e cadeias de valor responsáveis. (iii) Motivar os produtores rurais para a mudança de atitude com vistas a reverter o processo de degradação da paisagem e da produção e promover a recuperação de ambientes naturais. (iv) Conduzir a gestão, coordenação e monitoramento para garantir o alcance dos resultados esperados
As informações do Projeto Vertentes apresentadas no Observatório permitem a visualização dos municípios e núcleos contemplados e a gestão de camadas com os cruzamentos de todos os dados contidos no acervo disponível aos usuários e a consulta de relatórios predefinidos, tais como: Projeto Vertentes e Unidades de Conservação, Projetos Vertentes e Terras Indígenas; Projetos Vertentes e Imóveis Rurais declarados no Cadastro Ambiental Rural - CAR e Projetos Vertentes e Nascentes declaradas no CAR.
`,
    },
    agronordeste: {
      title: 'Agronordeste',
      description: `Descubra novas formas de cruzar informações agropecuárias por meio de mapas:`,
    },
    forests: {
      title: 'Florestas',
      description: `O painel de Florestas contém dados e informações sobre as florestas do Brasil, como o valor dos produtos florestais comercializados, os empregos gerados e a principais unidades da federação empregadoras. Além disso, inclui informações sobre a cobertura florestal do país, como a área total de florestas nativas e plantadas e a quantidade de espécies identificadas, e sobre os estoques de volume de madeira, de biomassa e de carbono das florestas, com a possibilidade de aplicação de filtros por regiões, unidades federativas, municípios, biomas e anos. Outro conjunto de informações relevante são as áreas legalmente protegidas, como áreas de preservação permanente, reserva legal, terras indígenas, unidades de conservação e áreas militares. O painel apresenta ainda dados de produção madeireira, uma série histórica de comparação da participação das florestas nativas e de cultivo no valor da produção florestal, e dados sobre produtos não madeireiros, como valor e volume.`,
    },
    climate: {
      title: 'Agricultura e clima',
      description: `Placeholder`,
    },
  },
  home: {
    text_01: 'Observatório da Agropecuária Brasileira',
    text_02: 'A Agropecuária do Brasil em um único lugar.',
    text_03: 'Confira as plataformas e painéis disponíveis.',
    text_04: 'Encontre dados integrados de várias bases em representações numéricas, tabulares e gráficas.',
    text_05: 'Visualize informações geoespacializadas de diversos temas em múltiplas camadas e possibilidades.',
    text_06: 'Elabore seus relatórios preferidos com os dados disponíveis no Observatório.',
    text_07: 'A agropecuária para todos.',
    text_08: 'Conecte-se ao Observatório.',
    text_09: 'Venha fazer parte desta rede!',
    text_10: 'Receba no seu e-mail informações e novidades das várias áreas temáticas do Observatório da Agropecuária Brasileira.',
    text_11: 'Cadastre-se e receba mais informações.',
    text_12: 'Nome',
    text_13: 'E-mail',
    text_14: 'Telefone de contato',
    text_15: 'Área de atuação',
    text_16: 'Cadastrar',
    text_17: 'Cadastro realizado com sucesso.',
    text_18: 'Obrigado!',
    text_19: 'O nome digitado é muito curto.',
    text_20: 'O e-mail digitado é muito curto.',
    text_21: 'O telefone digitado é muito curto.',
    text_22: 'A área de atuação digitada é muito curta.',
    text_23: 'Valor destinado ao Plano Safra 23/24',
    text_24: 'Previsão do Valor Bruto da Produção Agropecuária 2023 (09/2023)',
    text_25: 'Valor das exportações brasileiras da agropecuária 2023 (09/2023)',
    text_26: 'Saldo de postos de trabalho gerados pela agropecuária em 2023 (até 04/2023)',
    text_27: 'Dados do Observatório na palma da mão!',
    text_28: '*Somados os valores para Agricultura Empresarial e Familiar.',
    ctaButtonText: 'Saiba mais',
  },
  institutional: {
    text_01: 'Uma iniciativa para valorizar a Agropecuária Brasileira',
    text_02:
      'O Observatório da Agropecuária Brasileira é uma inovadora solução tecnológica que sistematiza, integra e disponibiliza um gigantesco conjunto de dados e informações da agricultura e pecuária do País - e também mundiais -, provendo subsídios aos processos de tomada de decisão e de formulação de políticas públicas, ao tempo em que revela este virtuoso setor e oportuniza o equacionamento de seus principais desafios.',
    text_03: 'Nosso Objetivo',
    text_04:
      'O Observatório da Agropecuária Brasileira tem a missão de transformar dados em informações assertivas e oferecer uma grande capacidade de integração e análise dessas informações numéricas, gráficas, cartográficas e geoespaciais das diversas cadeias produtivas do agronegócio.',
    text_05: 'Base de dados integradas',
    text_06: 'Parceiros envolvidos no desenvolvimento',
    text_07: 'Base de dados geoespacializadas',
    text_08: 'Setembro',
    text_09: 'Inauguração oficial',
    text_10: 'Kick off do projeto, com a presença de autoridades e parceiros.',
    text_11: 'Novembro',
    text_12: 'Portifólio de dados do Observatório',
    text_13: 'Mapeamento dos bancos de dados e oficina de requisição de dados estratégicos.',
    text_14: 'Março',
    text_15: 'Início da Jornada de Descoberta',
    text_16: 'Entrevistas com os principais gestores e parceiros.',
    text_17: 'Julho',
    text_18: 'Prototipagem',
    text_19: 'Prototipação da Plataforma e início da sua implementação.',
    text_20: 'Maio',
    text_21: 'Lançamento do Portal',
    text_22: 'Lançamento da 1ª versão do Observatório, com a Plataforma Estatística e a Geoespacial.',
    text_23: 'Junho',
    text_24: '2ª versão do Portal',
    text_25: 'Lançamento da 2ª versão do Portal com novos painéis e implementações.',
    text_26: 'O MAPA e o Observatório',
    text_27:
      'A agropecuária é multifacetada e há grande quantidade de sistemas e bases de dados, dispersos nos domínios do governo e da iniciativa privada, que são de interesse para a gestão estratégica e inovadora do Ministério e para o setor. Como mentor e gestor do Observatório, o MAPA, por intermédio da Secretaria de Política Agrícola (SPA), exerce um papel determinante na interlocução com seus parceiros e na definição de temas, informações e soluções a serem disponibilizados na plataforma.',
    text_28: 'Onde estamos inseridos',
  },
  partners: {
    text_01:
      'Aqui se encontra a relação dos parceiros mais atuantes ou que compartilham grande volume de dados com o Observatório. Seja um parceiro e dissemine seus dados para o Brasil e para o mundo!',
    text_02: 'Visitar site',
    anater: {
      title: 'ANATER',
      description: `A Agência Nacional de Assistência Técnica e Extensão Rural - Anater foi constituída com a proposta de promover, coordenar e implementar programas para o fortalecimento e sustentabilidade dos serviços de assistência técnica e extensão rural no Brasil, com vistas à inovação tecnológica e a apropriação de conhecimentos científicos de natureza técnica, econômica, ambiental e social. 
			<br><br>
			Entre suas finalidades, está a promoção do sistema de pesquisa agropecuária e do sistema de assistência técnica e extensão rural, fomentando o aperfeiçoamento e a geração de novas tecnologias. Além disso, realiza o credenciamento e a creditação de pessoas físicas e jurídicas prestadoras de serviços de assistência técnica e extensão rural. 
			<br><br>
			Um dos desafios da Anater é o de reorganizar o sistema de assistência técnica e extensão rural (ATER) para garantir mais acesso ao serviço aos produtores de todo o Brasil, especialmente aos agricultores familiares, que são responsáveis por cerca de 70% dos alimentos que chegam à mesa dos brasileiros.
			<br><br>
			Concentrada na viabilização e qualificação do serviço de ATER em todo território nacional, a Anater tem participação importante no Observatório da Agropecuária Brasileira, disponibilizando dados acerca dos contratos firmados entre a Agência e as entidades prestadoras de serviços de ATER. 
			<br><br>
			A integração dessas informações às de outras bases do Observatório possibilitará a composição de painéis e mapas voltados à análise do comportamento da assistência técnica em todo o país, da aplicação de técnicas que contribuam para o desenvolvimento rural sustentável e do monitoramento do desenvolvimento das culturas como resultado dos trabalhos de qualificação. `,
    },
    bank: {
      title: 'Banco Central do Brasil',
      description: `O Banco Central do Brasil é uma autarquia federal ligada ao Ministério da Economia, que tem como missão assegurar a estabilidade do poder de compra da moeda e um sistema financeiro sólido e eficiente. Além disso, a instituição é responsável por executar a estratégia estabelecida pelo Conselho Monetário Nacional (CMN) para manter a inflação sob controle. Para garantir o cumprimento desses importantes mandatos, a autarquia tem adotado as melhores práticas de governança pública e reforçado, continuamente, seus valores institucionais.
			<br><br>
			Por meio da disponibilização de dados em sua posse, o Banco Central do Brasil participa da Política de Dados Abertos do Poder Executivo Federal e acredita que essa divulgação gera inúmeros efeitos positivos à sociedade: fomenta o controle social; racionaliza gastos; e estimula a inovação em setores que prestam serviços aos cidadãos. O intuito é ampliar e aprimorar a transparência ativa por meio da abertura de dados públicos, com eficiência e qualidade, de forma a contribuir para reforçar a credibilidade e o cumprimento da missão institucional do Bacen, bem como fomentar o controle social, o aperfeiçoamento da integridade e da governança pública, a redução de custos e a participação social.
			<br><br>
			Na Agricultura e Pecuária, principal matriz econômica brasileira, uma parte considerável da movimentação de recursos de financiamento da produção brasileira é registrada e monitorada pelo Banco Central, por meio do desenvolvimento, manutenção e evolução do Sicor - O Sistema de Operações do Crédito Rural e do Proagro. O Sistema registra todas as operações de crédito rural deferidas pelas instituições financeiras integrantes do Sistema Nacional de Crédito Rural (SNCR), bem como as dos enquadramentos de empreendimentos no Programa de Garantia da Atividade Agropecuária (Proagro).
			<br><br>
			A partir da disponibilização e dos ajustes dos serviços de integração dos dados abertos pelo Banco Central, o Observatório da Agropecuária Brasileira atualiza automaticamente, consolida e disponibiliza as informações sobre o desempenho do crédito rural brasileiro, em todas as finalidades, programas, subprogramas e categorias de classificação. Isso possibilita a interpretação inclusive dos investimentos do Governo Federal em políticas públicas de fomento específicas. Aplicando modernas técnicas de usabilidade, bem como ferramentas de interpretação e apresentação gráfica dos dados de estatística descritiva, o Observatório da Agropecuária Brasileira torna acessíveis à sociedade, em painel temático específico, os dados sobre o desempenho do crédito rural no Brasil em conjunto com outras informações estratégicas para a Agropecuária Brasileira.`,
    },
    cna: {
      title: 'CNA',
      description: `A Confederação da Agricultura e Pecuária do Brasil (CNA) é uma entidade sindical patronal que representa 5 milhões de produtores rurais brasileiros, de pequeno, médio e grande portes e de variados ramos de atividade. Criada em 1951, a CNA consolidou-se ao longo dos últimos anos como o principal fórum de discussões e decisões do agronegócio brasileiro. Integram o Sistema CNA as 27 federações de agricultura e pecuária, que atuam nos Estados e no Distrito Federal, mais de dois mil sindicatos rurais - responsáveis pelas ações de apoio direto aos produtores rurais nos municípios -, o Serviço Nacional de Aprendizagem Rural (SENAR) e o Instituto CNA.
			<br><br>
			A CNA congrega associações e lideranças rurais e participa, de forma ativa e permanente, das discussões e decisões sobre a política nacional agrícola. No cumprimento de sua missão institucional, a CNA representa, organiza e fortalece os produtores rurais brasileiros, defendendo seus direitos e interesses, promovendo o desenvolvimento econômico e social do setor agropecuário.
			<br><br>
			Como um dos produtos da profícua e estreita parceria da CNA com o governo federal, especificamente com o Ministério da Agricultura e Pecuári - Mapa, está o Observatório da Agropecuária Brasileira, no qual a CNA aporta investimentos diretos, no âmbito da materialização física, em salas interativas altamente tecnológicas e na cocriação das principais linhas de análise, interpretação e integração de dados. Assim, contribui com a visão e com as necessidades do produtor rural quanto à geração de conhecimento, atualizado e acessível, para definição de estratégias competitivas e de sustentabilidade para a agropecuária brasileira.`,
    },
    conab: {
      title: 'CONAB',
      description: `A Companhia Nacional de Abastecimento (Conab) é uma empresa pública vinculada ao Ministério da Agricultura e Pecuária (Mapa). Atua em duas grandes frentes: a geração de informações sobre o agronegócio e a execução de políticas voltadas ao abastecimento e segurança alimentar. Possui superintendência em todos os estados e também no Distrito Federal, mantendo ativos centenas de profissionais em todo o Brasil. A Conab também conta com 68 Unidades Armazenadoras estrategicamente distribuídas.
			<br><br>
			Para embasar ações do agronegócio e também políticas públicas relativas ao setor e à segurança alimentar, a Conab coleta, analisa e disponibiliza informações periodicamente. São estimativas de produção agrícola, acompanhamento climático e espectral das lavouras, custos de produção, preços agropecuários, de insumos e de fretes, análise contínua da relação de oferta e demanda de grãos, entre outros trabalhos.
			<br><br>
			Além disso, em 2020, a Conab reforçou a atuação na pecuária, com foco em sete setores: pecuária bovina de corte e de leite, frango, suíno, ovinos, caprinos e ovos. A divulgação periódica de informações pecuárias será feita a partir de 2021.
			<br><br>
			O desenvolvimento contínuo da equipe Conab é reforçado pelo relacionamento com parceiros no país e no exterior, o que posiciona as ações da companhia em convergência com as metodologias e discussões de vanguarda no agronegócio mundial.
			<br><br>
			A Companhia Nacional de Abastecimento é pioneira na parceria de integração de conteúdos com o Observatório da Agropecuária Brasileira. Tem importância estratégica como geradora e guardiã de robusto banco de dados, disponibilizando-o em seus portais e, agora, no Observatório. Por meio de ferramentas que integram diferentes bancos de dados, divulga conteúdo de interesse estratégico para o planejamento do agronegócio, para os setores correlacionados e para a definição e execução de políticas públicas.`,
    },
    embrapa: {
      title: 'EMBRAPA',
      description: `A Empresa Brasileira de Pesquisa Agropecuária (Embrapa) é uma organização pública de pesquisa, desenvolvimento e inovação, que atua na geração de conhecimento e de tecnologias para a produção de alimentos, de fibras e de fontes de energia. Sua missão é viabilizar soluções de pesquisa, de desenvolvimento e de inovação para a sustentabilidade da agricultura em benefício da sociedade brasileira. 
			<br><br>
			Instituída pela Lei n° 5.851 de 1972, tem como objetivo principal fortalecer sua capacidade em responder às demandas da agropecuária brasileira e, ao mesmo tempo, antecipar e enfrentar os desafios do futuro.
			<br><br>
			É uma empresa com espírito global, que, ao longo de sua história, tem construído uma sólida rede de cooperação internacional. Atualmente, está presente em todos os continentes. Tem parcerias com algumas das principais instituições e redes de pesquisa do mundo, o que também contribui com o programa de cooperação técnica do Governo Brasileiro, que busca transferir e adaptar tecnologias nacionais para a realidade tropical de diferentes países.
			<br><br>
			A Embrapa tem como visão ser protagonista e parceira essencial na geração e no uso de conhecimentos para o desenvolvimento sustentável da agricultura brasileira até 2030. Nesse sentido, possui diversos dados, oriundos de estudos e programas em parceria com o Mapa e outras instituições, cujo conhecimento é fundamental para a tomada de decisões estratégicas, para o mercado, e para o avanço da ciência e novas tecnologias.
			<br><br>
			A parceria entre Embrapa e Observatório se faz com a disponibilização de informações pelo painel temático do Programa Nacional de Solos (PronaSolos), componente fundamental de integração de dados do Mapa com o levantamento e a interpretação dos solos brasileiros. Além desse, está em processo de estruturação o painel temático com as Megatendências da Pecuária de Corte para 2030.`,
    },
    ibge: {
      title: 'IBGE',
      description: `O Instituto Brasileiro de Geografia e Estatística (IBGE) é a instituição vinculada ao Ministério da Economia responsável pela produção e análise de informações estatísticas e geográficas, bem como pela coordenação dos sistemas estatístico e cartográfico do Brasil, constituindo-se no principal provedor de dados e informações sobre o país. 
			<br><br>
			O IBGE detém um volume espetacular de dados sobre a agropecuária brasileira. São informações econômico-financeiras, de produção, bens e serviços consumidos, e emprego, entre outros aspectos, específicas das atividades da agricultura, pecuária, produção florestal, pesca e aquicultura. Assim, esses dados são imprescindíveis ao governo e aos setores abarcados, que se beneficiam desses para o amplo conhecimento das cadeias, as tomadas de decisão e, por parte da administração pública, a elaboração das políticas agropecuárias. 
			<br><br>
			Entre os essenciais trabalhos do Órgão estão o Levantamento Sistemático da Produção Agropecuária (LSPA), a Pesquisa Trimestral do Leite, a Produção Agrícola Municipal (PAM), a Pesquisa da Pecuária Municipal (PPM) e o Censo Agropecuário, este que delinea toda a atividade agrícola e pecuária brasileira, trazendo o número e área dos estabelecimentos, as atividades neles desenvolvidos e características do produtor. Os dados do IBGE, notadamente os da PAM, PPM e do Censo 2017, se encontram em praticamente todos os painéis do Observatório. Isto reforça o grau de importância desses dados para a ampla visão sobre o segmento agropecuário, que é ainda mais enriquecida com o cruzamento com dados de outras fontes no Observatório.`,
    },
    incra: {
      title: 'INCRA',
      description: `O Instituto Nacional de Colonização e Reforma Agrária (Incra) é uma autarquia federal cuja missão principal é executar a reforma agrária e realizar o ordenamento fundiário nacional.
			<br><br>
			Criado pelo Decreto nº 1.110, de 9 de julho de 1970, o Incra tem o objetivo de possibilitar uma distribuição de terra mais igualitária, realizar a justiça social e fomentar o desenvolvimento social e econômico do campo, com ações e programas que assegurem a oportunidade de acesso à propriedade de terra, condicionada à sua função social.
			<br><br>
			Atualmente, o Instituto está em todo o território nacional. Tem 29 superintendências regionais e 49 unidades avançadas e busca ser referência internacional em soluções de inclusão social. Conta também com parcerias com governos estaduais e prefeituras, aos quais oferece instrumentos que viabilizam a chegada dos serviços de autarquia aos produtores rurais e a transformação de sua realidade e a de suas famílias.
			<br><br>
			O Incra possui bases de dados muito relevantes para a gestão eficiente da agropecuária do país, como o Sistema Nacional de Cadastro Rural (SNCR) e o Cadastro Nacional de Imóveis Rurais (CNIR). Elas têm caráter estrutural, pois seus dados são cruzados com os de outras instituições, o que gera informações capazes de qualificar políticas públicas, servindo como instrumento de fiscalização.
			<br><br>
			A parceria do Instituto com o Observatório, que se inicia com a utilização dos dados do Sistema de Gestão Fundiária (SIGEF), é fundamental para a obtenção de informação segura e consolidada, já que visa assegurar que essa e outras bases do próprio Incra e de outros parceiros sejam estudadas, integradas e disponibilizadas.`,
    },
    inmet: {
      title: 'INMET',
      description: `A missão do Instituto Nacional de Meteorologia (Inmet), órgão do Ministério da Agricultura e Pecuária, é prover informações meteorológicas à sociedade brasileira e influir construtivamente no processo de tomada de decisão, contribuindo para o desenvolvimento sustentável do País. Essa tarefa é alcançada por meio de monitoramento, análise e previsão de tempo e de clima, que se fundamentam em pesquisa aplicada, trabalho em parceria e compartilhamento do conhecimento, com ênfase em resultados práticos e confiáveis.
			<br><br>
			São atribuições do Inmet: elaborar e divulgar, diariamente, em nível nacional, a previsão do tempo, avisos e boletins meteorológicos especiais; promover a execução de estudos e levantamentos meteorológicos e climatológicos aplicados à agricultura e outras atividades correlatas; coordenar, elaborar e executar programas e projetos de pesquisas agrometeorológicas e de acompanhamento das variações climáticas ; estabelecer, coordenar e operar as redes de observações meteorológicas e de transmissão de dados, inclusive aquelas integradas à rede internacional; propor a programação e acompanhar a implementação de capacitação e treinamento de recursos humanos, em atendimento a demandas técnicas específicas.
			<br><br>
			O Inmet representa o Brasil junto à Organização Meteorológica Mundial (OMM) e, por sua delegação, é responsável pelo tráfego das mensagens coletadas pela rede de observação meteorológica da América do Sul e pelos demais centros meteorológicos que compõem o Sistema de Vigilância Meteorológica Mundial.
			<br><br>
			No Observatório da agropecuária brasileira, o Inmet abriga os sistemas e as aplicações do Observatório na infraestrutura de tecnologia da informação do Instituto. Contribui, também, com a divulgação de informações através de modernos e seguros serviços automatizados de consulta e compartilhamento de dados meteorológicos.`,
    },
    lapig: {
      title: 'LAPIG',
      description: `O Laboratório de Processamento de Imagens e Geoprocessamento (Lapig) da Universidade Federal de Goiás (UFG) configura-se como uma importante frente de atuação vinculada às atividades de ensino e extensão. Por meio de parcerias estabelecidas com importantes universidades e centros de pesquisa, no Brasil e no exterior, realiza a produção e/ou organização de dados geográficos e documentais voltados ao monitoramento territorial e ambiental dos biomas brasileiros e respectivas paisagens naturais e antrópicas. 
			<br><br>
			Com a missão de contribuir para tornar as informações geográficas e ambientais úteis e acessíveis, e tendo por visão ser referência internacional na produção e na organização de dados geográficos, bem como no desenvolvimento de soluções para a melhor governança territorial e ambiental das paisagens brasileiras, o LAPIG tem produzido informações muito relevantes para estudos e análises de dados geográficos do País. 
			<br><br>
			O Atlas das Pastagens é uma ferramenta desenvolvida pelo Lapig que dispõe de dados relacionados às pastagens brasileiras, com informações geograficamente especializadas. Entre os dados disponibilizados, encontram-se as áreas de pastagem, qualidade, estoque de carbono nas pastagens, entre outros, com mapeamentos baseados em dados Landsat que possibilitam a visualização dos níveis de degradação das pastagens, a obtenção de estimativas do estoque de carbono no solo nas raízes e na biomassa aérea das pastagens do bioma cerrado. Por conter informações muito relevantes para a agricultura sustentável, essas foram incorporadas às camadas do painel de Agricultura Sustentável e Meio Ambiente do Observatório, e podem ser cruzadas com outras dezenas de camadas, possibilitando a ampliação do conhecimento sobre o tema e suas variações.`,
    },
    observarei: {
      title: 'Observarei',
      description: `O OBSERVAREI - Observatório do Empreendedorismo Inovador tem como seu objetivo principal o desenvolvimento econômico da região da Amurel através de iniciativas construídas sobre a estratégia da criação, desenvolvimento, ampliação e consolidação de ecossistemas de inovação nos municípios que formam a região da AMUREL.`,
    },
    sfb: {
      title: 'Serviço Florestal Brasileiro',
      description: `O Serviço Florestal Brasileiro (SFB), órgão especializado em Florestas e vinculado ao Ministério da Agricultura Pecuária e Abastecimento - Mapa, tem a missão de promover o conhecimento, o uso sustentável e a ampliação da cobertura florestal no Brasil, tornando a agenda florestal estratégica para a economia do país. Tem a missão institucional de ser reconhecido pela inovação, agilidade e qualidade na implementação de políticas públicas, além de prestar serviços para o desenvolvimento sustentável das cadeias produtivas agropecuárias. Atualmente, o SFB desenvolve, alimenta e administra diversas bases de dados para as estratégias de desenvolvimento agropecuário e florestal sustentável no Brasil.
			<br><br>
			É mantenedor do SNIF - Sistema Nacional de Informações Florestais, que centraliza dados sobre a produção florestal, e do Inventário Florestal Nacional (IFN), com base de dados primários sobre a vegetação e as variáveis sociais e de meio físico relativas às florestas nativas do Brasil.
			<br><br>
			Além disso, o IFN oferece informações a respeito das concessões florestais. Destaca-se, entre elas, o banco de dados do Sicar - Sistema de Cadastro Ambiental Rural, por meio do qual os produtores rurais brasileiros, de maneira autodeclaratória, disponibilizaram informações 100% georreferenciadas sobre o perímetro e disponibilidade de vegetação nativa em suas posses e em propriedades rurais. Nele, inserem-se também dados sobre o macroplanejamento de uso do solo no imóvel rural, com a identificação das suas áreas de preservação permanente e reservas legais, sob a égide do código florestal brasileiro (Lei Federal 12.651/2012).
			<br><br>
			Em interface com o Observatório da Agropecuária Brasileira, o SFB/Mapa se configura como instituição muito relevante e estratégica, considerando que o Sicar é a maior base de dados territoriais sobre imóveis rurais do mundo. Além disso, é um banco de dados primordial, do ponto de vista estratégico, para a definição e o planejamento de políticas públicas pautadas no desenvolvimento rural integrado e municiado das mais modernas estratégias de inteligência territorial e geoespacial.
			<br><br>
			Dentre as atribuições do Serviço Florestal Brasileiro, alinhadas às diretrizes estratégicas de gestão do Mapa, está ainda o fomento à expansão da cobertura de vegetação nativa, à produção sustentável e à regularização ambiental de imóveis rurais no Brasil. Com base nos dados florestais e territoriais do SFB com cobertura para todo o país, aliados a outras bases de dados integradas no Observatório da Agropecuária Brasileira, serão possíveis análises de tendências e oportunidades para efetivação das políticas públicas, iniciativas de produção rural sustentável e pagamento por serviços ambientais, a partir das florestas em imóveis rurais no Brasil.`,
    },
  },
  library: {
    text_01:
      'Biblioteca com filtros, contendo arquivos diversos, e em cada card o tipo, o título, a fonte, o formato do arquivo e sua referência temporal para realizar o download.',
    macro_01: 'Agricultura digital',
    macro_02: 'Boletins climáticos para o agro',
    macro_03: 'Documentos gerais',
    macro_04: 'Documentos técnicos',
    macro_05: 'Gestão da produção agrícola',
    macro_06: 'Projeções do agro no Brasil',
    macro_07: 'Reforma agrária',
    macro_08: 'Sustentabilidade e meio ambiente',
  },
  metadata: {
    text_01:
      'Aqui você encontra os Metadados básicos do Observatório, separados em Plataforma Geoespacial e Plataforma Estatística. Os Metadados são dados que descrevem os dados, que possibilitam o conhecimento do acervo, bem como gerenciá-lo.',
  },
  platforms: {
    text_01: `A Plataforma Estatística é um ambiente do Observatório que disponibiliza informações de interesse da Agropecuária Brasileira, por meio de dados numéricos, tabulares e representações gráficas acerca dos diversos temas abordados.
		A Plataforma está organizada com filtros por período, estratificações a nível nacional, estadual e municipal, além de dados quantitativos e qualitativos.
		Os dados são disponibilizados por fontes oficiais, abertos ao público, com uma abordagem profunda das variáveis apresentadas, em diferentes níveis, permitindo ao usuário melhor interação e estudo das informações selecionadas.`,
    text_02: `A Plataforma Geoespacial é um ambiente do Observatório dedicado à integração de dados e informações territoriais de interesse da agropecuária brasileira, por intermédio dos quais os dados geoespaciais podem ser visualizados e construídos de acordo com a necessidade e interpretação dos usuários. A plataforma está organizada por ambiente de visualização de camadas, relatórios quantitativos e painéis temáticos que detenham qualquer tipo de atributo e representação cartográfica dos dados. A Plataforma Geoespacial dispõe de diversos bancos de dados georreferenciados de fontes oficiais, abertos ao público, em um mesmo ambiente e com ferramentas de interatividade acopladas.`,
    text_03: `A ferramenta de Relatórios Dinâmicos do Observatório da Agropecuária é a mais completa e avançada ferramenta disponível para criar relatórios amplos, completos e de fácil compreensão, organizados e dispostos de forma simplificada e de fácil exportação.`,
    text_04: 'Acesse a plataforma de relatórios',
    text_05: 'Acesse todos os painéis',
  },
  footer: {
    address: 'Endereço',
    rights: '© 2022 Todos os direitos reservados.',
  },
}

const eng = {
  commons: {
    access: 'Access',
    soon: 'Coming soon',
    statistical: 'Statistical',
    statisticalPlatform: 'Statistical Platform ',
    statisticalCta: 'Find agricultural information in numbers, tables and graphs:',
    statisticalAccess: 'Access the Statistical Platform',
    geo: 'Geospatial',
    geoPlatform: 'Geospatial Platform ',
    geoCta: 'Discover new ways of cross-referencing agricultural information through maps:',
    geoAccess: 'Access the Geospatial Platform',
    reports: 'Reports',
    reportsPlatform: 'Reports Platform',
    platforms: 'Data platforms',
    thematicPanels: 'Thematic Dashboards',
    new: 'new!',
    font: 'Font',
    start: 'Home',
    institutional: 'Institutional',
    partners: 'Partners',
    library: 'Library',
    metadata: 'Metadata',
    allPanels: 'All Dashboards',
    observatory: 'Observatory',
    billions: 'billions',
    billionsStar: 'billions*',
    trillions: 'trillions',
  },
  panels: {
    comex: {
      title: 'Foreign trade',
      description:
        'The foreign trade dashboard highlights the participation of Brazilian products in the international flow of agribusiness, a great generator of wealth for the country. The version brings, through numerical representations, graphics, tables and maps of the Statistical Platform, information on volume and value of imports and exports, the main trading partners, performance by state and the places of entry and exit of goods, extracted of the Agrostat and Siscomex/Comex Stat systems. The available filters allow very flexible queries, complemented with the spatial visualization of the importing/exporting countries and the location of the Federal Revenue Service units in the country, where the goods leave/enter, stamping the dimension of Brazilian foreign trade.',
    },
    pecuary: {
      title: 'Livestock',
      description: `The Livestock Thematic Panel gathers data from one of the most relevant productive sectors in our country, providing access to general and specific information on each Brazilian livestock production system.
Based on the data sources of the National Supply Company (CONAB), the Brazilian Institute of Geography and Statistics (IBGE), the Brazilian Agricultural Research Corporation (EMBRAPA), and the Image Processing and Geoprocessing Laboratory of the Federal University of Goiás (LAPIG/UFG), information is available that helps the knowledge on the production chain in this sector.
The Livestock Thematic Panel presents data classified by period, region, Federative Unit, and municipality. Furthermore, it is possible to identify the types of animal production, the prevailing activities, amount of herds, and slaughter, among others.
`,
    },
    sisbi: {
      title: 'Brazilian Inspection System',
      shortTitle: 'SISBI',
      description:
        'The SISBI dashboard has, in its first version, information on state, district and municipal inspection services for products of animal origin, individually or linked to public consortia of municipalities, as well as establishments and products registered in these services and registered by them in the electronic system e-SISBI. In the dashboard, the user will be able to obtain this public information through graphs, maps or spreadsheets, built on the basis of the records made by the aforementioned inspection services and establishments, and by MAPA itself. The information can be useful for official inspection services and other inspection bodies, rural extension services, certifiers, entrepreneurs, educators, researchers and any interested citizen. With this, MAPA seeks to contribute more and more to the management and greater transparency within the scope of SISBI.',
    },
    familyFarming: {
      title: 'Family Farming',
      description:
        'The Panel provides, through geospatial and statistical views, information to support social and productive inclusion strategies for income generation for family farmers. Highlights in this version are the availability of graphs, maps, and reports regarding the Declaration of Aptitude to the National Program for the Strengthening of Family Farming (PRONAF) and also concerning the Food Acquisition Program (PAA), which is operated by the National Supply Company (CONAB).',
    },
    agroSus: {
      title: 'Sustainable Agriculture and Environment',
      shortTitle: 'Sust. Agri. and Env.',
      description:
        'The Sustainable Agriculture and Environment dashboard makes available, through the Geospatial Platform, consolidated information on irrigation, sustainable farming projects, environmental regulation, among other topics, from different sources and databases. Extracted through spatial and tabular intersections, the information is presented both spatially and in the format of succinct reports, with numbers and graphs.',
    },
    aquaculture: {
      title: 'Aquaculture',
      description:
        'The Thematic dashboard on Aquaculture presents information on the production of fish, shrimp, and shellfish (in tons), fish fingerlings, shrimp larvae, and shellfish seeds (in thousands), as well as the value (in R$) of production of these and other animals (frogs, alligators, etc.). This information can be sorted by species, year, region, states, and municipalities, according to the survey carried out by IBGE, in the Municipal Livestock Production (PPM). The platform aims to support public actions and policies for the development of the aquaculture chain of production. It will impact positively the sector by providing transparency, enabling new investments, and providing fast and easy access to aquaculture information to users/stakeholders.',
    },
    tech: {
      title: 'Technical Assistance',
      description:
        "The Brazilian Agricultural Observatory's Technical Assistance dashboard presents, through its Geospatial Platform, data on technical assistance provided in the national territory. This version's highlight is the availability of maps, graphs, and reports generated from the crossing of significant databases in the sector, integrating a dynamic platform on the distribution of care provided, as well as the main activities assisted, resulting in an unprecedented set of information in this relevant segment.",
    },
    fundiary: {
      title: 'Land Affairs',
      description:
        'The Land Affairs dashboard aims to provide clear information about land issues in the national territory, through the Geospatial and Statistical Platforms. Geospacial enables the visualization of layers of certified public and private properties, embargoed areas and information from the Rural Environmental Registry (CAR), allowing for data crossing and reporting. On the Statistical Platform, it is possible to obtain information on titling in federal public lands and in settlements of the National Institute of Colonization and Agrarian Reform (Incra). The filters applied to the dashboard offer visualization by period, region, federative unit and municipality (check the information on municipalities by the Regional Superintendence), allowing an performance analysis of the title concession proccess of each region, as well as providing a detailed and personalized view of land activities.',
    },
    credit: {
      title: 'Rural Credit',
      description:
        "In order to consult information about public rural credit, this thematic dashboard makes available, in a consolidated and interactive way, data from Brazil's Central Bank's Sicor - System for Rural Credit Operations, and Proagro - Program of Guarantee to Agricultural Activity, regarding the quantity and value of contracts in four areas: funding, investment, commercialization, and industrialization. The filters applied on the dashboard offer visualization by Period, Source of Funds, Program, Subprogram, Activity, region, state, and municipality, allowing an analysis of the performance of public rural credit programs and a detailed, customized view of the structured data and information, according to the needs and demands of each user.",
    },
    fertilizers: {
      title: 'Fertilizers',
      description:
        'The Fertilizer dashboard of the Brazilian Agricultural Observatory brings together various information about the sector in the same environment. The Statistical Platform integrates the databases shared by MAPA, CONAB, ME, MRE, FAO, and ANDA, and provides the user an environment of easy and fast access, suited to usability practices and user experience. This first version of the dashboard provides graphs, tables, and maps with important information on the fertilizer sector, such as imports, exports, a list of suppliers, and totals, among others.',
    },
    prod: {
      title: 'Agricultural Products',
      description: `A nova versão do Painel de Produtos Agrícolas dispõe agora de 18 produtos. Além da inclusão de novas culturas, os dados podem ser visualizados por safra, por tipo e também traz os resultados do valor bruto da produção dos últimos 10 anos.
      O painel se propõe a apresentar em um único ambiente informações sobre crédito, cultivo, custos, disponibilidades e mercado interno e externo de importantes culturas agrícolas brasileiras. Complementadas com dados de zoneamento agrícola e de clima, propicia uma ampla visão para o governo, produtores, cooperativas, pesquisadores, tomadores de decisão e demais usuários. 
      Além do MAPA, as informações integradas têm por fonte a Conab, o Banco Central do Brasil, o IBGE e o Ministério da Economia.
        `,
    },
    pronasolos: {
      title: 'National Program of Brazilian Soils',
      shortTitle: 'PronaSolos',
      description:
        'The soils of Brazil are going through a promising phase of refinement and improvement of mapping and inventory scales, as well as consolidation of the research and spatial data bases already available, through a broad sectorial and institutional integration of PronaSolos – the National Program of Brazilian Soils. The thematic dashboard of PronaSolos in the Observatory presents, in an interactive way, the spatial databases of soils at a national level, currently available in Brazil in a 1:250,000 scale, allowing the statistical visualization of the percentage of occupied area in Brazil and in each Federation Unit.',
    },
    zarc: {
      title: 'Agricultural Zoning of Climate Risk',
      shortTitle: 'ZARC',
      description:
        'The ZARC thematic dashboard has the purpose of presenting consolidated information from all zoning ordinances for each crop, group, time of year and soil type. The dashboard also offers integrated tools so as to promote greater usability and intuitiveness in the interpretation of the information, centralizing the table of risk, the list of cultivars, and also maps to visualize the data by municipality, state and region.',
    },
    geographicIndications: {
      title: 'Indicações Geográficas',
      description: `As Indicações Geográficas se referem a produtos ou serviços que tenham uma origem geográfica específica. Seu registro reconhece reputação, qualidades e características que estão vinculadas ao local. Como resultado, elas comunicam ao mundo que certa região se especializou e tem capacidade de produzir um artigo/prestar um serviço diferenciado e de excelência.
      Na Plataforma Geoespacial disponibilizamos por meio de camadas, a visualização dos lugares associados a produtos ou serviços típicos relacionados, sejam eles Indicações Geográficas (IG) ou Produtos Típicos Potenciais (PTP).
      A Indicação Geográfica se constitui sob duas formas: a Indicação de Procedência e a Denominação de Origem. A Indicação de Procedência é o nome geográfico de país, cidade, região ou localidade de seu território, que se tenha tornado conhecido como centro de extração, produção ou fabricação de determinado produto ou de prestação de determinado serviço. Já a Denominação de Origem é o nome geográfico de país, cidade, região ou localidade de seu território, que designe produto ou serviço cujas qualidades ou características se devam exclusiva ou essencialmente ao meio geográfico, incluídos fatores naturais e humanos.
      Os produtos agropecuários foram destacados por fazerem parte do âmbito de atuação do Ministério, bem como devido à importância social, cultural e econômica para suas regiões.`,
    },
    hydricResources: {
      title: 'Recursos Hídricos e Agricultura',
      description: `O Painel Recursos Hídricos e Agricultura contempla dados e camadas geoespacializadas com ênfase nas informações sobre a Irrigação e sua relevância para a produção agrícola brasileira.
      É possível visualizar as informações sobre a hidrografia (nos seus 7 níveis), área irrigada atual e adicional efetiva e total; Polos Nacionais de Irrigação, Outorgas, Tipologias da Irrigação, dentre outras. Mantendo o padrão de consulta dos diversos temas contidos no acervo do Observatório da Agropecuária Brasileira é dado aos diferentes usuários do setor a possibilidade de fazer a gestão e o cruzamento desse tema com as demais camadas contidas no banco de dados do Portal.
      A fonte utilizada foi o Atlas de Irrigação 2021 disponível na página da Agência Nacional de Águas - ANA.`,
      link: true,
    },
    agroCensus: {
      title: 'Destaques do Censo Agropecuário',
      shortTitle: 'Destaques do Censo Agro.',
      description:
        'O painel do Censo Agropecuário 2017 destaca alguns dos principais dados divulgados pelo Instituto Brasileiro de Geografia e Estatística (IBGE), que retratam a realidade do campo por meio da investigação estatística e territorial sobre a produção agropecuária brasileira. Entre os dados disponibilizados na Plataforma Geoespacial estão o perfil do produtor, sua atividade econômica, os usos da terra e da agricultura familiar, possibilitando a estratificação regional, estadual e municipal. O painel também permite habilitar camadas dos outros temas disponíveis no Observatório, ampliando as possibilidades de cruzamento de informações e de  análise do segmento agropecuário.',
    },
    vertentes: {
      title: 'Vertentes',
      description: `O “Projeto Vertentes” – Consórcios de paisagem de uso múltiplo sustentável - Sistemas alimentares, uso do solo e restauração, financiado pelo Banco Mundial - BIRD está sendo executado pelo Ministério da Agricultura e Pecuária - MAPA, Ministério do Meio Ambiente - MMA e Serviço Nacional de Aprendizagem Rural - SENAR.
O objetivo principal do Projeto é aumentar a área sob gestão sustentável da paisagem e recomposição nas cadeias de valor da pecuária de corte e da soja em paisagens selecionadas no Brasil. 
Os objetivos específicos são: (i) Formar Consórcios Locais com vistas a criar capacidade e conhecimento necessários para apoiar o planejamento, a governança e os principais investimentos da região. (ii) Promover a adoção de práticas sustentáveis de produção de alimentos e cadeias de valor responsáveis. (iii) Motivar os produtores rurais para a mudança de atitude com vistas a reverter o processo de degradação da paisagem e da produção e promover a recuperação de ambientes naturais. (iv) Conduzir a gestão, coordenação e monitoramento para garantir o alcance dos resultados esperados
As informações do Projeto Vertentes apresentadas no Observatório permitem a visualização dos municípios e núcleos contemplados e a gestão de camadas com os cruzamentos de todos os dados contidos no acervo disponível aos usuários e a consulta de relatórios predefinidos, tais como: Projeto Vertentes e Unidades de Conservação, Projetos Vertentes e Terras Indígenas; Projetos Vertentes e Imóveis Rurais declarados no Cadastro Ambiental Rural - CAR e Projetos Vertentes e Nascentes declaradas no CAR.
`,
    },
    agronordeste: {
      title: 'Agronordeste',
      description: `Descubra novas formas de cruzar informações agropecuárias por meio de mapas:`,
    },
    forests: {
      title: 'Forests',
      description: `O painel de Florestas contém dados e informações sobre as florestas do Brasil, como o valor dos produtos florestais comercializados, os empregos gerados e a principais unidades da federação empregadoras. Além disso, inclui informações sobre a cobertura florestal do país, como a área total de florestas nativas e plantadas e a quantidade de espécies identificadas, e sobre os estoques de volume de madeira, de biomassa e de carbono das florestas, com a possibilidade de aplicação de filtros por regiões, unidades federativas, municípios, biomas e anos. Outro conjunto de informações relevante são as áreas legalmente protegidas, como áreas de preservação permanente, reserva legal, terras indígenas, unidades de conservação e áreas militares. O painel apresenta ainda dados de produção madeireira, uma série histórica de comparação da participação das florestas nativas e de cultivo no valor da produção florestal, e dados sobre produtos não madeireiros, como valor e volume.`,
    },
    climate: {
      title: 'Agriculture and climate',
      description: `Placeholder`,
    },
  },
  home: {
    text_01: 'Observatory of Brazilian Agriculture',
    text_02: "Brazil's Agriculture in a single place.",
    text_03: 'Check out the available dashboards and data platforms.',
    text_04: 'Find integrated data from multiple bases in numerical, tabular and graphical representations.',
    text_05: 'Visualize geospatialized information from various themes in multiple layers and possibilities.',
    text_06: 'Build your favorite reports with available data within the Observatory.',
    text_07: 'Agriculture for all.',
    text_08: 'Connect to the Observatory.',
    text_09: 'Come be part of this network!',
    text_10: 'Receive in your e-mail information and news from the various thematic areas of the Observatory of Brazilian Agriculture.',
    text_11: 'Register and receive more information.',
    text_12: 'Name',
    text_13: 'E-mail',
    text_14: 'Contact phone',
    text_15: 'Area of work',
    text_16: 'Register',
    text_17: 'Success.',
    text_18: 'Thank you!',
    text_19: 'The name you typed is too short.',
    text_20: 'The e-mail you typed is too short.',
    text_21: 'The phone you typed is too short.',
    text_22: 'The field of work you typed is too short.',
    text_23: 'Amount allocated to the 23/24 Crop Plan',
    text_24: 'Forecast of Gross Value of Agricultural Production 2023 (09/2023)',
    text_25: 'Value of Brazilian Agricultural Exports 2023 (09/2023)',
    text_26: 'Balance of jobs generated by agriculture and livestock in 2023 (until 04/2023)',
    text_27: 'Observatory data in the palm of your hand!',
    text_28: '*Values ​​for Business and Family Agriculture are added.',
    ctaButtonText: 'Learn more',
  },
  institutional: {
    text_01: 'An initiative to enhance the value of Brazilian agriculture and livestock activities',
    text_02:
      'The Observatory of Brazilian Agriculture is an innovative technological solution that systematizes, integrates and makes available a gigantic set of data and information on agriculture and livestock in the country - and also worldwide -, providing subsidies to the decision-making processes and the formulation of public policies, while revealing this virtuous sector and providing an opportunity to address its main challenges.',
    text_03: 'Our goal',
    text_04:
      'The Observatory of Brazilian Agriculture has the mission of transforming data into assertive information and offering a great capacity for integration and analysis of numerical, graphic, cartographic and geospatial information from the various agribusiness production chains.',
    text_05: 'Integrated database',
    text_06: 'Partners involved in development',
    text_07: 'Geospatialized database',
    text_08: 'September',
    text_09: 'Official inauguration',
    text_10: 'Kick off the project, with the presence of authorities and partners.',
    text_11: 'November',
    text_12: 'Observatory data portfolio',
    text_13: 'Mapping of databases and strategic data request workshop.',
    text_14: 'March',
    text_15: 'Beginning of the Journey of Discovery',
    text_16: 'Interviews with key managers and partners.',
    text_17: 'July',
    text_18: 'Prototyping',
    text_19: 'Platform prototyping and start of its implementation.',
    text_20: 'May',
    text_21: 'Portal Launch',
    text_22: 'Launch of the 1st version of the Observatory, with the Statistical and Geospatial Platform.',
    text_23: 'June',
    text_24: '2nd version of the Portal',
    text_25: 'Launch of the 2nd version of the Portal with new dashboards and implementations.',
    text_26: 'MAPA and the Observatory',
    text_27:
      'Agriculture is multifaceted and there are a large number of systems and databases, dispersed in the domains of the government and the private sector, which are of interest for the strategic and innovative management of the Ministry and for the sector. As mentor and manager of the Observatory, MAPA, through the Secretariat of Agricultural Policy (SPA), plays a decisive role in the dialogue with its partners and in the definition of themes, information and solutions to be made available on the platform.',
    text_28: 'Where we are located',
  },
  partners: {
    text_01:
      'Here you can find the list of the most active partners or those who share a large volume of data with the Observatory. Be a partner and spread your data to Brazil and the world!',
    text_02: 'Visit website',
    anater: {
      title: 'National Agency of Technical Assistance and Rural Extension',
      description: `The National Agency of Technical Assistance and Rural Extension (Anater) was established with the purpose of promoting, coordinating, and implementing programs to strengthen technical assistance and rural extension services in Brazil, aimed at technological innovation and appropriation of scientific knowledge of technical, economic, environmental, and social nature.
			<br><br>
			The Agency promotes the agricultural research system and the technical assistance and rural extension system, fostering the enhancement and generation of new technologies. In addition, it authorizes and accredits individual and corporate providers of technical assistance and rural extension services.
			<br><br>
			One challenge Anater faces is to reorganize the technical assistance and rural extension system (ATER) to guarantee wider access to the service among producers across Brazil, particularly to family farmers, who produce around 70% of all of the food consumed at Brazilian homes.
			<br><br>
			Focused on ensuring that ATER services are feasible and up to standard nationwide, Anater takes an important part in the Observatory of Brazilian Agriculture, providing data and information on contracts executed between the Agency and entities providing ATER services.
			<br><br>
			By integrating this information into other Observatory’s databases, dashboards and maps can be created aimed at analyzing the technical assistance behavior throughout the country, the application of techniques that contribute to sustainable rural development and the monitoring of crop development as a result of the qualification’s efforts. `,
    },
    bank: {
      title: 'Central Bank of Brazil',
      description: `The Central Bank of Brazil (BCB) is a federal institution under the aegis of the Ministry of the Economy, whose mission is to ensure the stability of the currency purchasing power, to foster a sound, efficient and competitive financial system, and to promote the economic well-being of society. Moreover, the institution is responsible for executing the strategy set forth by the National Monetary Council (CMN) to maintain inflation under control. To ensure that these important mandates are fulfilled, the Bank adopts best public governance practices and is continually reinforcing its institutional values.
			<br><br>
			By providing the information it holds, the Central Bank of Brazil is a participant in the Federal Executive Branch's Open Data Policy and believes that it generates countless positive impacts on society: it encourages social control, rationalizes expenditures, and stimulates innovation in sectors that provide services to citizens. The goal is to expand and enhance active transparency by opening up public data, efficiently and with quality, in order to reinforce the credibility and the accomplishment of the Central Bank of Brazil's institutional mission, and also to promote social control, improvement of integrity and of public governance, cost reduction, and social participation.
			<br><br>
			Agriculture is Brazil's main economic driver and a substantial amount of the transactions to finance the country's production is registered and monitored by the Central Bank, through the development, maintenance, and evolution of the Sicor (System of Rural Credit and Proagro Operations). The System registers all rural credit operations granted by the financial institutions that are members of the National Rural Credit System (SNCR), as well as the operations that classify ventures within the Rural Credit Insurance Program (Proagro). 
			<br><br>
			Based on the open data integration services provided by the Central Bank, the Observatory of Brazilian Agriculture automatically updates, consolidates, and provides information on rural credit performance in Brazil, its purposes, programs, subprograms, and classification categories. This enables the interpretation of federal government investments in specific public development policies. By applying modern usability techniques, along with tools for graphic interpretation and presentation of descriptive statistical data, the Observatory makes this data on rural credit performance accessible to society, through a specific thematic dashboard, along with other strategic information to Brazilian agriculture.`,
    },
    cna: {
      title: 'Brazilian Confederation of Agriculture and Livestock',
      description: `The Brazilian Confederation of Agriculture and Livestock (CNA) is an employer's organization that represents over five million small, medium and large-scale Brazilian rural producers engaged in various areas of activity. Founded in 1951, CNA has established itself as the main forum for agribusiness discussions and decisions in Brazil. The CNA System encompasses 27 agriculture and livestock federations that operate at the States and in the Federal District, over 2,000 rural unions (responsible to provide direct support to farmers at the municipal level), the National Rural Learning Service (SENAR), and the CNA Institute.
			<br><br>
			CNA congregates rural associations and leaders and, actively and permanently, participates in discussions and decisions on national agricultural policy. In the performance of its institutional mission, CNA represents, organizes, and strengthens Brazilian rural producers, advocating for their rights and interests, while promoting economic and social development in agriculture.
			<br><br>
			One of the products of the fruitful and close partnership between the CNA and the federal government, and particularly with the Ministry of Agriculture, Livestock and Supply (Mapa), is the Observatory of Brazilian Agriculture, in which the CNA makes direct investments in its physical structure, in high-tech and interactive operation rooms, and in co-creating key lines of data analysis, interpretation and integration. This way, it contributes by sharing the rural producers' vision and needs regarding the generation of up-to-date and accessible knowledge, in order to define competitive and sustainability strategies for Brazilian agriculture.`,
    },
    conab: {
      title: 'National Supply Company',
      description: `The National Supply Company (Conab) is a government-run company under the aegis of the Ministry of Agriculture, Livestock, and Supply (Mapa). It works on two major fronts: generating information on agribusiness and executing policies geared towards food supply and food security. It has a superintendency in every state and one in the Federal District, with hundreds of active professionals across Brazil. Conab also has 68 strategically located Storage Units.
			<br><br>
			Conab periodically collects, analyzes, and provides information to be used as the basis of agribusiness actions and of public policies related to the sector and to food security. These include agricultural production estimates, climate and spectral monitoring of plantations, production costs, pricing related to agriculture and livestock, input supplies and freight, continued analysis of the supply and demand ratio, and other projects.
			<br><br>
			Moreover, in 2020 Conab reinforced its activities on livestock farming by focusing on seven sectors: beef and dairy cattle, chicken, pork, sheep, goats, and eggs. Livestock farming information will be periodically published starting in 2021.
			<br><br>
			The continuous development of the Conab team is reinforced by its relationships with partners in Brazil and abroad, positioning the company's actions in convergence with vaguard methodologies and discussions regarding international agribusiness.
			<br><br>
			The National Supply Company is a pioneer in the content integration partnership stablished with the Observatory of Brazilian Agriculture. It is strategically important as the generator and the guardian of a robust database, which is made available on its own portals and now, through the Observatory. Using tools that integrate different databases, it publishes contents that are of strategic interest to agribusiness planning, to related sectors, and to determine and execute public policies.`,
    },
    embrapa: {
      title: 'Brazilian Agricultural Research Corporation',
      description: `The Brazilian Agricultural Research Corporation (Embrapa) is a government-run organization, which works with knowledge and technology generation towards the production of food, fibers, and sources of energy. Its mission is to provide research, development, and innovation solutions for the sustainability of agriculture and for the benefit Brazilian society.
			<br><br>
			Embrapa was established by Law no. 5.851 of 1972, with the main goal of strengthening its ability to respond to the demands of Brazilian agriculture and, at the same time, to anticipate and to confront future challenges.
			<br><br>
			It is a company with a global spirit that has built a solid network of international cooperation throughout its history. It is currently active on every continent. It has partnered with some of the world's major research institutes and networks, which contributes to the Brazilian government's technical cooperation program, aimed at transferring, and adapting national technologies to tropical realities in different countries.
			<br><br>
			Embrapa's vision is to be a world reference in the generation and supply of information, knowledge and technologies for the sustainable development of Brazilian agriculture by 2030. It possesses a variety of data originated from studies and programs carried out in partnership with MAPA and other institutions. The knowledge regarding these data is fundamental to strategic decision-making, to the market, and to advancing science and new technologies.
			<br><br>
			The partnership between Embrapa and the Observatory provides information through the thematic dashboard of the National Soils Program (PronaSolos), a fundamental component of MAPA’s data integration with the survey and interpretation of Brazilian soils. A thematic dashboard on Mega Trends in Livestock Production for 2030 is also being developed.`,
    },
    ibge: {
      title: 'IBGE',
      description: `The Brazilian Institute of Geography and Statistics (IBGE) is the institution linked to the Ministry of Economy responsible for the production and analysis of statistical and geographic information, as well as for the coordination of statistical and cartographic systems in Brazil, constituting the main provider of data and country information. 
			<br><br>
			The IBGE has a spectacular volume of data on Brazilian agriculture. They are economic-financial information, production, goods and services consumed, and employment, among other aspects, specific to the activities of agriculture, livestock, forestry production, fishing and aquaculture. Thus, these data are essential for the government and the sectors involved, which benefit from them for the broad knowledge of the chains, decision-making and, on the part of the public administration, the elaboration of agricultural policies. 
			<br><br>
			Among the essential works of the Organ are the Systematic Survey of Agricultural Production (LSPA), the Quarterly Milk Survey, the Municipal Agricultural Production (PAM), the Municipal Livestock Survey (PPM) and the Agricultural Census, which outlines all the Brazilian agriculture and livestock activities, bringing the number and area of ​​establishments, the activities developed in them and characteristics of the producer. IBGE data, notably those from PAM, PPM and the 2017 Census, can be found on practically all the Observatory's dashboards. This reinforces the degree of importance of these data for the broad view of the agricultural sector, which is further enriched with the crossing with data from other sources in the Observatory.`,
    },
    incra: {
      title: 'National Institute of Colonization and Agrarian Reform',
      description: `The National Institute of Colonization and Agrarian Reform (Incra) is a federal authority whose main mission is to execute agrarian reform and carry out land-use planning at the national level.
			<br><br>
			Incra was created by Decree no. 1.110, dated July 9, 1970, with the goal of enabling more equal land distribution, providing social justice, and fostering rural social and economic development, with actions and programs that guarantee opportunities to access land ownership, conditioned upon its social role.
			<br><br>
			The Institute is currently present throughout Brazil. With its 29 regional superintendencies and 49 advanced units, it seeks to be an international reference for social inclusion solutions. Incra has also partnered with state and municipal governments, offering instruments that enable its services to reach farmers and to transform their realities and of their families.
			<br><br>
			Incra has databases that are highly relevant to efficient agricultural management in the country, such as the National Rural Registry System (SNCR) and the National Registry of Rural Properties (CNIR). They are structural in nature, since their data are cross-referenced against data from other institutions, generating information that can be used to qualify public policies, serving as an oversight instrument.
			<br><br>
			The Institute's partnership with the Observatory, which began with the use of the Land Management System (SIGEF) data, is essential to obtain reliable and consolidated information, since it aims to assure that these and other databases held by Incra and other partners are studied, integrated, and made available.`,
    },
    inmet: {
      title: 'National Institute of Meteorology',
      description: `The mission of the National Institute Meteorology (Inmet), an unit of the Ministry of Agriculture, Livestock and Supply, is to provide meteorological information to Brazilian society and constructively influence the decision-making process, contributing to the country's sustainable development. This goal is achieved through the monitoring, analysis, and forecasting of weather and climate, which is based on applied research, work carried out through partnerships, and knowledge sharing, with an emphasis on practical and reliable results.
			<br><br>
			Inmet's mandate includes: to create and to publish weather forecasts, notices and special meteorological bulletins, at national level, on a daily basis; to promote the execution of meteorological and climatic studies and surveys applied to agriculture and other related activities; to coordinate, create and execute programs and projects related to agrometeorological research and climate change monitoring; to establish, coordinate and operate the meteorological observation and data transmission networks, including those integrated into the international network; to propose the programming for and to monitor the implementation of human resources training, to meet specific technical demands.
			<br><br>
			Inmet represents Brazil at the World Meteorological Organization (WMO) and, through its delegation, is responsible for the traffic of messages collected by the meteorological observation network of South America and by the other meteorological centers that constitute the World Weather Watch System.
			<br><br>
			Inmet hosts the Observatory's systems and applications, using the Institute's information technology infrastructure. It also contributes by releasing information through modern and reliable automated services to search and share meteorological data.`,
    },
    lapig: {
      title: 'LAPIG',
      description: `The Image Processing and Geoprocessing Laboratory (Lapig) at the Federal University of Goiás (UFG) is an important front of action linked to teaching and extension activities. Through partnerships established with important universities and research centers, in Brazil and abroad, it produces and/or organizes geographic and documentary data aimed at territorial and environmental monitoring of Brazilian biomes and their respective natural and human landscapes. 
			<br><br>
			With the mission of contributing to make geographic and environmental information useful and accessible, and having the vision to be an international reference in the production and organization of geographic data, as well as in the development of solutions for the best territorial and environmental governance of Brazilian landscapes, the LAPIG has produced very relevant information for studies and analysis of geographic data in the country. 
			<br><br>
			The Pastures Atlas is a tool developed by Lapig that has data related to Brazilian pastures, with geographically specialized information. Among the available data, there are pasture areas, quality, carbon stock in pastures, among others, with mappings based on Landsat data that allow the visualization of pasture degradation levels, obtaining estimates of carbon stock in the soil in the roots and aerial biomass of pastures in the Cerrado biome. As they contain information that is very relevant to sustainable agriculture, these were incorporated into the layers of the Observatory's Sustainable Agriculture and Environment dashboard, and can be crossed with dozens of other layers, enabling the expansion of knowledge on the topic and its variations.`,
    },
    observarei: {
      title: 'Observarei',
      description: `The OBSERVAREI - Innovative Entrepreneurship Observatory has as its main objective the economic development of the Amurel region through initiatives built on the strategy of creating, developing, expanding, and consolidating innovation ecosystems in the municipalities that make up the AMUREL region.`,
    },
    sfb: {
      title: 'Brazilian Forest Service',
      description: `The Brazilian Forest Service (SFB) is MAPA’s specialized unit that works with Forests. Its mission is to promote the knowledge, the sustainable use and the expansion of the forest cover in Brazil, making the forestry agenda strategic to the country's economy. The Service's institutional mission is to be recognized for innovation, agility, and quality in implementing public policies, while also providing services for the sustainable development of agricultural production chains. The SFB currently develops, provides inputs for, and administers databases for sustainable agricultural and forestry development strategies in Brazil. 
			<br><br>
			It maintains the National System of Forest Information (SNIF), which centralizes data on forest production, as well as the National Forest Inventory (IFN), with its primary database on vegetation and social and physical variables related to Brazil's native forests.
			<br><br>
			Additionally, SFB provides information regarding forest concessions, and the Rural Environmental Registry System (Sicar), through which Brazilian farmers self-declare and provide 100% georeferenced information on the perimeter and the availability of native vegetation on their rural properties. It also includes data on rural property land use macro planning, identifying permanent preservation and legal reserve areas, under the auspices of Brazil's Forest Code (Federal Law 12.651/2012).
			<br><br>
			Through its interface with the Observatory of Brazilian Agriculture, the SFB/Mapa has established itself as a highly relevant and strategic institution. Sicar is one of the world's largest territorial database of rural properties. Furthermore, it is an essential database from a strategic standpoint, in terms of determining and planning public policies based on integrated rural development and based upon the most modern territorial intelligence and geospatial strategies.
			<br><br>
			In line with the Ministry of Agriculture's strategic management guidelines, the Brazilian Forest Service's mandate also includes fostering the expansion of native plant cover, the sustainable production, and the environmental regularization of rural properties in Brazil. The use of the SFB's forest and land data, with its nationwide coverage, along with the other databases that are part of the Observatory of Brazilian Agriculture, will enable the analyses of trends and opportunities to implement public policies, sustainable farming initiatives, and payment for environmental services, based on forests assets located on rural properties in Brazil.`,
    },
  },
  library: {
    text_01:
      'Library with filters, containing several files, and in each card the type, title, font, file format and its time reference to download.',
    macro_01: 'Digital Agriculture',
    macro_02: 'Climate bulletins for agriculture',
    macro_03: 'General Documents',
    macro_04: 'Technical Documents',
    macro_05: 'Agricultural production management',
    macro_06: 'Agri Projections in Brazil',
    macro_07: 'Agrarian Reform',
    macro_08: 'Sustainability and Environment',
  },
  metadata: {
    text_01:
      "Here you can find the Observatory's basic Metadata, separated into Geospatial Platform and Statistical Platform. Metadata is data that describes the data, which makes it possible to know the collection, as well as to manage it.",
  },
  platforms: {
    text_01: `The Statistical Platform is an environment of the Observatory that provides information through numerical and tabular data and graphical representations regarding the various topics covered. The platform is organized with filters by period, stratification at the national, state, and municipal levels, and quantitative and qualitative data. The data is provided through official sources, open to the public, with a deep approach to the variables presented, at different levels, allowing the user more interaction and to better study the selected information. `,
    text_02: `The Geospatial Platform is an environment of the Observatory dedicated to the integration of data and territorial information of interest to Brazilian agriculture and livestock activities, through which geospatial data can be visualized and built according to the needs and interpretation of users. The platform is organized into layer visualization environment, quantitative reports and thematic dashboards that hold attribute and cartographic representation of the data. The Geospatial Platform has several georeferenced databases from official sources, open to the public, in a single environment and with interactive tools attached.`,
    text_03: `The Agricultural Observatory's Dynamic Reports tool is the most complete and advanced tool available to create broad, complete and easy-to-understand reports, organized and arranged in a simplified and easy-to-export form.`,
    text_04: 'Access Reports Platform ',
    text_05: 'Access all platforms',
  },
  footer: {
    address: 'Address',
    rights: '© 2022 All rights reserved.',
  },
}

const esp = {
  commons: {
    access: 'Acceso',
    soon: 'Pronto',
    statistical: 'Estadística',
    statisticalPlatform: 'Plataforma Estadística',
    statisticalCta: 'Encuentre la información agrícola en números, tablas y gráficos:',
    statisticalAccess: 'Acceda a la plataforma estadística',
    geo: 'Geoespacial',
    geoPlatform: 'Plataforma Geoespacial',
    geoCta: 'Descubrid nuevas formas de cruzar la información agrícola a través de mapas:',
    geoAccess: 'Acceda a la plataforma geoespacial',
    reports: 'Informes',
    reportsPlatform: 'Plataforma de Informes',
    platforms: 'Plataformas',
    thematicPanels: 'Paneles Temáticos',
    new: 'nuevo!',
    font: 'Fuente',
    start: 'Comienzo',
    institutional: 'Institucional',
    partners: 'Socios',
    library: 'Biblioteca',
    metadata: 'Metadatos',
    allPanels: 'Todos los paneles',
    observatory: 'Observatorio',
    billions: 'mil millones',
    billionsStar: 'mil millones*',
    trillions: 'trillones',
  },
  panels: {
    comex: {
      title: 'Comercio Exterior',
      description:
        'El panel de comercio exterior destaca la participación de los productos brasileños en el flujo internacional del agronegocio, gran generador de riqueza para el país. La versión trae, a través de representaciones numéricas, gráficos, tablas y mapas del Panel Estadístico, informaciónes de volumenes y valores de las importaciones y exportaciones, los principales socios comerciales, desempeño por unidad federativa y los lugares de entrada y salida de mercancías, extraídos de los sistemas Agrostat y Siscomex/Comex Stat. Los filtros disponibles permiten consultas muy flexibles, complementadas con la visualización espacial de los países importadores/exportadores y la ubicación de las unidades de agencia tributária en el país, por donde salen/entran las mercancías, demostrando la dimensión del comercio exterior brasileño.',
    },
    pecuary: {
      title: 'Ganadería',
      description: `El Panel Temático de Ganadería reúne datos de uno de los sectores productivos más importantes de nuestro país, permitiendo el acceso a información general y específica de cada uno de los sistemas productivos ganaderos brasileños.           	
Con la ayuda de fuentes de datos de la Compañía Nacional de Abastecimiento - CONAB, del Instituto Brasileño de Geografía y Estadística - IBGE, de la Empresa Brasileña de Investigación Agropecuaria - Embrapa y del Laboratorio de Procesamiento y Geoprocesamiento de Imágenes de la Universidad Federal de Goiás - Lapig/UFG, se dispone de información que ayuda a comprender la cadena de producción de este sector.
El Panel Temático de Ganadería presenta los datos clasificados por periodo, región, unidad federativa y municipio. Además, también es posible identificar los tipos de producción animal, las actividades predominantes, el número de rebaños y los volúmenes de sacrificio, entre otros.
`,
    },
    sisbi: {
      title: 'Sistema Brasileño de Inspección (SISBI)',
      shortTitle: 'SISBI',
      description:
        'El Panel del SISBI cuenta, en su primera versión, con información sobre los servicios de inspección estatales, distritales y municipales de productos de origen animal, individualmente o vinculados a consorcios públicos de municipios, así como de establecimientos y productos registrados en estos servicios y registrados por éstos en el sistema electrónico e-SISBI. En el Panel, el usuario podrá obtener esta información pública a través de gráficos, mapas u hojas de cálculo, construidos a partir de los registros realizados por los citados servicios y establecimientos de inspección, y por el propio Ministério de Agricultura (MAPA). La información puede ser de utilidad para los servicios oficiales de inspección y otros organismos de inspección, servicios de extensión rural, certificadores, empresarios, educadores, investigadores y cualquier ciudadano interesado. Con esto, el MAPA busca contribuir cada vez más a la gestión y mayor transparencia en el ámbito del SISBI.',
    },
    familyFarming: {
      title: 'Agricultura Familiar',
      description:
        'El panel de agricultura familiar del Observatorio de la Agropecuaria Brasileña proporciona, a través de las visiones geoespacial y estadística, información para apoyar las estrategias de inclusión social y productiva para la generación de ingresos de agricultores familiares. En esta versión destaca la inclusión de gráficos, mapas e informes referentes a la Declaración de Aptitud al Pronaf (Programa Nacional de Fortalecimiento de la Agricultura Familiar) y al Programa de Adquisición de Alimentos (PAA), operado por la Compañía Nacional de Abastecimiento (Conab).',
    },
    agroSus: {
      title: 'Sector Agropecuario Sostenible y Medio Ambiente',
      shortTitle: 'Sec. Agro. Sos. y Medio Amb.',
      description:
        'El panel Agricultura Sostenible y Medio Ambiente pone a disposición, a través de la Plataforma Geoespacial, información consolidada sobre riego, proyectos de agricultura sostenible, regulación ambiental, entre otros temas, de diferentes fuentes y bases de datos. Extraído a través de intersecciones espaciales y tabulares, la información se presenta tanto espacialmente como en formato de informes sucintos, con números y gráficos.',
    },
    aquaculture: {
      title: 'Acuicultura',
      description:
        'El panel temático de acuicultura presenta informaciónes sobre la producción de peces, camarones y moluscos, alevines de peces, larvas de camarón y semillas de mariscos, así como el valor (R$) de la producción de estos y otros animales (rana, caimán, etc.). Esa información puede ser consultada por especie, año, región, estados y municipios, siguiendo la encuesta realizada por el IBGE, en la Producción Pecuaria Municipal - PPM. La plataforma tiene como objetivo permitir el direccionamiento de acciones y políticas públicas para el desarrollo de la cadena acuícola, así como impactar positivamente en el sector, proveyendo transparencia y posibilitando nuevas inversiones, ofreciendo acceso rápido y fácil a la información acuícola a los usuarios/interesados.',
    },
    tech: {
      title: 'Asistencia técnica',
      description:
        'El Panel de Asistencia Técnica del Observatorio de la Agropecuaria Brasileña dispone, a través de la Plataforma Geoespacial, de datos sobre la asistencia técnica prestada en el territorio nacional. Esta versión destaca la disponibilidad de mapas, gráficos e informes generados a partir del cruce de importantes bases de datos del sector, componiendo una plataforma dinámica sobre la distribución de la atención prestada, así como las principales actividades atendidas, resultando en un conjunto de información sin precedentes en este segmento importante.',
    },
    fundiary: {
      title: 'Asuntos Fundiarios',
      description:
        'El Panel de Asuntos Fundiarios tiene como objetivo brindar información clara sobre los temas territoriales del país, a través de las Plataformas Geoespacial y Estadística. La Geoespacial permite la visualización de capas de propiedades públicas y privadas certificadas, áreas embargadas e información del Registro Ambiental Rural (CAR), lo que permite cruzar estos datos y hacer reportes. En la Plataforma Estadística, es posible obtener información sobre la titulación en tierras públicas federales y en asentamientos del Instituto Nacional de Colonización y Reforma Agraria (Incra). Los filtros aplicados al panel ofrecen visualización por período, región, unidad federativa y município, permitiendo un análisis del desempeño de títulos de cada región, además de brindar una visión detallada y personalizada de actividades territoriales.',
    },
    credit: {
      title: 'Crédito Rural',
      description:
        'Para consultar la información sobre crédito rural, este panel temático pone a disposición, de manera consolidada e interactiva, los datos provenientes del Sicor (Sistema de Operaciones de Crédito Rural) y del Proagro, del Banco Central de Brasil, para cantidad y valor de los contratos en cuatro propósitos: costeo, inversión, comercialización e industrialización. Los filtros aplicados en el panel permiten la visualización por periodo, fuente de recursos, programa, subprograma, actividad, producto, región, unidad federativa y Municipio, para un análisis del desempeño del crédito rural y una visión detallada y personalizada de los datos, además de información estructurada, según la necesidad y la demanda de cada usuario.',
    },
    fertilizers: {
      title: 'Fertilizantes',
      description:
        'El Panel de Fertilizantes del Observatorio de la Agropecuaria Brasileña reúne las diversas informaciones sobre el sector en un mismo ambiente. A través de la plataforma estadística, integramos las bases de datos compartidas por MAPA, CONAB, ME, MRE, FAO y ANDA, y proveemos al usuario un ambiente de fácil y rápido acceso, adecuado a las prácticas de usabilidad y experiencia del usuario. En esta primera versión del panel se encuentran disponibles gráficos, tablas y mapas, que contienen informaciónes importantes del sector de fertilizantes, como importaciones, exportaciones, listado de proveedores, números totales, entre otros datos.',
    },
    prod: {
      title: 'Productos Agrícolas',
      description: `A nova versão do Painel de Produtos Agrícolas dispõe agora de 18 produtos. Além da inclusão de novas culturas, os dados podem ser visualizados por safra, por tipo e também traz os resultados do valor bruto da produção dos últimos 10 anos.
      O painel se propõe a apresentar em um único ambiente informações sobre crédito, cultivo, custos, disponibilidades e mercado interno e externo de importantes culturas agrícolas brasileiras. Complementadas com dados de zoneamento agrícola e de clima, propicia uma ampla visão para o governo, produtores, cooperativas, pesquisadores, tomadores de decisão e demais usuários. 
      Além do MAPA, as informações integradas têm por fonte a Conab, o Banco Central do Brasil, o IBGE e o Ministério da Economia.
        `,
    },
    pronasolos: {
      title: 'Programa Nacional de Suelos de Brasil',
      shortTitle: 'PronaSolos',
      description:
        'Los suelos de Brasil están pasando por una fase prometedora de perfeccionamiento y mejora en escala del mapeo y el inventario, así como de la consolidación de las investigaciones espaciales ya disponibles, a través de la amplia integración sectorial e institucional del PronaSolos - Programa Nacional de Suelos de Brasil. El panel temático de PronaSolos en el Observatorio presenta, de manera interactiva, las bases de datos espaciales de suelos a nivel nacional, actualmente disponibles en Brasil a una escala de 1:250.000, permitiendo la visualización estadística del porcentaje de área ocupada en Brasil y en cada Unidad de la Federación.',
    },
    zarc: {
      title: 'Zonificación Agrícola de Riesgo Climático',
      shortTitle: 'ZARC',
      description:
        'El panel temático del ZARC tiene como objetivo presentar información consolidada de todas las ordenanzas de zonificación para cada cultivo, grupo, período del año y tipo de suelo, por médio de las Plataformas Estadística y Geoespacial. El panel también ofrece herramientas integradas, con el fin de promover una mayor usabilidad e intuitividad en la interpretación de la información, centralizando la tabla de riesgo, el listado de cultivares y también mapas de visualización de los datos por municipio, estado y región.',
    },
    geographicIndications: {
      title: 'Indicações Geográficas',
      description: `As Indicações Geográficas se referem a produtos ou serviços que tenham uma origem geográfica específica. Seu registro reconhece reputação, qualidades e características que estão vinculadas ao local. Como resultado, elas comunicam ao mundo que certa região se especializou e tem capacidade de produzir um artigo/prestar um serviço diferenciado e de excelência.
      Na Plataforma Geoespacial disponibilizamos por meio de camadas, a visualização dos lugares associados a produtos ou serviços típicos relacionados, sejam eles Indicações Geográficas (IG) ou Produtos Típicos Potenciais (PTP).
      A Indicação Geográfica se constitui sob duas formas: a Indicação de Procedência e a Denominação de Origem. A Indicação de Procedência é o nome geográfico de país, cidade, região ou localidade de seu território, que se tenha tornado conhecido como centro de extração, produção ou fabricação de determinado produto ou de prestação de determinado serviço. Já a Denominação de Origem é o nome geográfico de país, cidade, região ou localidade de seu território, que designe produto ou serviço cujas qualidades ou características se devam exclusiva ou essencialmente ao meio geográfico, incluídos fatores naturais e humanos.
      Os produtos agropecuários foram destacados por fazerem parte do âmbito de atuação do Ministério, bem como devido à importância social, cultural e econômica para suas regiões.`,
    },
    hydricResources: {
      title: 'Recursos Hídricos e Agricultura',
      description: `O Painel Recursos Hídricos e Agricultura contempla dados e camadas geoespacializadas com ênfase nas informações sobre a Irrigação e sua relevância para a produção agrícola brasileira.
      É possível visualizar as informações sobre a hidrografia (nos seus 7 níveis), área irrigada atual e adicional efetiva e total; Polos Nacionais de Irrigação, Outorgas, Tipologias da Irrigação, dentre outras. Mantendo o padrão de consulta dos diversos temas contidos no acervo do Observatório da Agropecuária Brasileira é dado aos diferentes usuários do setor a possibilidade de fazer a gestão e o cruzamento desse tema com as demais camadas contidas no banco de dados do Portal.
      A fonte utilizada foi o Atlas de Irrigação 2021 disponível na página da Agência Nacional de Águas - ANA. `,
      link: true,
    },
    agroCensus: {
      title: 'Destaques do Censo Agropecuário',
      shortTitle: 'Destaques do Censo Agro.',
      description:
        'O painel do Censo Agropecuário 2017 destaca alguns dos principais dados divulgados pelo Instituto Brasileiro de Geografia e Estatística (IBGE), que retratam a realidade do campo por meio da investigação estatística e territorial sobre a produção agropecuária brasileira. Entre os dados disponibilizados na Plataforma Geoespacial estão o perfil do produtor, sua atividade econômica, os usos da terra e da agricultura familiar, possibilitando a estratificação regional, estadual e municipal. O painel também permite habilitar camadas dos outros temas disponíveis no Observatório, ampliando as possibilidades de cruzamento de informações e de  análise do segmento agropecuário.',
    },
    vertentes: {
      title: 'Vertentes',
      description: `O “Projeto Vertentes” – Consórcios de paisagem de uso múltiplo sustentável - Sistemas alimentares, uso do solo e restauração, financiado pelo Banco Mundial - BIRD está sendo executado pelo Ministério da Agricultura e Pecuária - MAPA, Ministério do Meio Ambiente - MMA e Serviço Nacional de Aprendizagem Rural - SENAR.
O objetivo principal do Projeto é aumentar a área sob gestão sustentável da paisagem e recomposição nas cadeias de valor da pecuária de corte e da soja em paisagens selecionadas no Brasil. 
Os objetivos específicos são: (i) Formar Consórcios Locais com vistas a criar capacidade e conhecimento necessários para apoiar o planejamento, a governança e os principais investimentos da região. (ii) Promover a adoção de práticas sustentáveis de produção de alimentos e cadeias de valor responsáveis. (iii) Motivar os produtores rurais para a mudança de atitude com vistas a reverter o processo de degradação da paisagem e da produção e promover a recuperação de ambientes naturais. (iv) Conduzir a gestão, coordenação e monitoramento para garantir o alcance dos resultados esperados
As informações do Projeto Vertentes apresentadas no Observatório permitem a visualização dos municípios e núcleos contemplados e a gestão de camadas com os cruzamentos de todos os dados contidos no acervo disponível aos usuários e a consulta de relatórios predefinidos, tais como: Projeto Vertentes e Unidades de Conservação, Projetos Vertentes e Terras Indígenas; Projetos Vertentes e Imóveis Rurais declarados no Cadastro Ambiental Rural - CAR e Projetos Vertentes e Nascentes declaradas no CAR.
`,
    },
    agronordeste: {
      title: 'Agronordeste',
      description: `Descubra novas formas de cruzar informações agropecuárias por meio de mapas:`,
    },
    forests: {
      title: 'Bosques',
      description: `O painel de Florestas contém dados e informações sobre as florestas do Brasil, como o valor dos produtos florestais comercializados, os empregos gerados e a principais unidades da federação empregadoras. Além disso, inclui informações sobre a cobertura florestal do país, como a área total de florestas nativas e plantadas e a quantidade de espécies identificadas, e sobre os estoques de volume de madeira, de biomassa e de carbono das florestas, com a possibilidade de aplicação de filtros por regiões, unidades federativas, municípios, biomas e anos. Outro conjunto de informações relevante são as áreas legalmente protegidas, como áreas de preservação permanente, reserva legal, terras indígenas, unidades de conservação e áreas militares. O painel apresenta ainda dados de produção madeireira, uma série histórica de comparação da participação das florestas nativas e de cultivo no valor da produção florestal, e dados sobre produtos não madeireiros, como valor e volume.`,
    },
    climate: {
      title: 'Agricultura y clima',
      description: `Placeholder`,
    },
  },
  home: {
    text_01: 'Observatorio de la Agropecuaria Brasileña',
    text_02: 'El sector agropecuario de Brasil en un solo lugar.',
    text_03: 'Verifique las plataformas y paneles disponibles.',
    text_04: 'Encuentre datos integrados de múltiples bases en representaciones numéricas, tabulares y gráficas.',
    text_05: 'Visualice informaciónes geoespaciales de varios temas en múltiples capas y posibilidades.',
    text_06: 'Elabora sus informes favoritos con los datos disponibles en el Observatorio.',
    text_07: 'La agropecuaria para todos.',
    text_08: 'Conéctate al Observatorio.',
    text_09: '¡Ven a ser parte de esta red!',
    text_10: 'Reciba en su correo electrónico informaciones y novedades de las diversas áreas temáticas del Observatório da Agropecuária Brasileira.',
    text_11: 'Regístrate y recibe más información.',
    text_12: 'Nombre',
    text_13: 'Correo electrónico',
    text_14: 'Número de teléfono de contacto',
    text_15: 'Área de actuación',
    text_16: 'Registrar',
    text_17: 'Éxito.',
    text_18: 'Gracias!',
    text_19: 'El nombre que ha escrito es demasiado corto.',
    text_20: 'El correo electrónico que ha escrito es demasiado corto.',
    text_21: 'El teléfono que escribiste es demasiado corto.',
    text_22: 'El campo de trabajo que ha escrito es demasiado corto.',
    text_23: 'Importe destinado al Plan de Cultivos 23/24',
    text_24: 'Previsión del Valor Bruto de la Producción Agropecuaria 2023 (09/2023)',
    text_25: 'Valor de las Exportaciones Agropecuarias Brasileñas 2023 (09/2023)',
    text_26: 'Balance de empleos generados por la agricultura y ganadería en 2023 (hasta 04/2023)',
    text_27: '¡Datos del observatorio en la palma de tu mano!',
    text_28: '*Se suman valores para la Agricultura Empresarial y Familiar.',
    ctaButtonText: 'Aprende más',
  },
  institutional: {
    text_01: 'Una iniciativa para valorar el sector agropecuario brasileño',
    text_02:
      'El Observatório de la Agropecuária Brasileña es una solución tecnológica innovadora que sistematiza, integra y pone a disposición un gigantesco conjunto de datos e informaciones sobre la agricultura y la ganadería de Brasil - y también del mundo -, ayudando a los procesos de toma de decisiones y formulación de políticas públicas, al mismo tiempo que revela este sector virtuoso y brinda una oportunidad para abordar sus principales desafíos.',
    text_03: 'Nuestro objetivo',
    text_04:
      'El Observatório de la Agropecuaria Brasileña tiene la misión de transformar los datos en información asertiva y ofrecer una gran capacidad de integración y análisis de informaciones numéricas, gráficas, cartográficas y geoespaciales de las diversas cadenas productivas del agronegocio.',
    text_05: 'Bases de datos integradas',
    text_06: 'Socios involucrados',
    text_07: 'Base de datos geoespaciales',
    text_08: 'Septiembre',
    text_09: 'Inauguración oficial',
    text_10: 'Lanzamiento del proyecto, con la presencia de autoridades y socios.',
    text_11: 'Noviembre',
    text_12: 'Portafolio de datos del Observatorio',
    text_13: 'Mapeo de bases de datos y taller de solicitud de datos estratégicos.',
    text_14: 'Marzo',
    text_15: 'Comienzo de la jornada de descubrimiento',
    text_16: 'Entrevistas con gestores y socios claves.',
    text_17: 'Julio',
    text_18: 'Prototipación',
    text_19: 'Prototipación de la plataforma e inicio de su implementación.',
    text_20: 'Mayo',
    text_21: 'Inauguración del Portal',
    text_22: 'Lanzamiento de la 1ª versión del Observatorio, con la Plataforma Estadística y Geoespacial.',
    text_23: 'Junio',
    text_24: 'Segunda versión del Portal',
    text_25: 'Lanzamiento de la segunda versión del Portal con nuevos paneles e implementaciones.',
    text_26: 'El MAPA y el Observatorio',
    text_27:
      'La agricultura es multifacética y existe una gran cantidad de sistemas y bases de datos, dispersos en los dominios del gobierno y del sector privado, que son de interés para la gestión estratégica e innovadora del Ministerio y del sector. Como mentor y gestor del Observatorio, el MAPA, a través de su Secretaría de Política Agraria (SPA), juega un papel determinante en el diálogo con sus socios y en la definición de temas, informaciones y soluciones para poner a disposición en la plataforma.',
    text_28: 'Dónde estamos insertados',
  },
  partners: {
    text_01:
      'Aquí puedes encontrar la lista de los socios más activos o que comparten un gran volumen de datos con el Observatorio. ¡Sé socio y difunde tus datos para Brasil y para el mundo!',
    text_02: 'visita la página web',
    anater: {
      title: 'Agencia Nacional de Asistencia Técnica y Extensión Rural',
      description: `La Agencia Nacional de Asistencia Técnica y Extensión Rural (Anater) se constituyó con el propósito de promover, coordinar e implementar programas para el fortalecimiento y la sostenibilidad de los servicios de asistencia técnica y extensión rural en Brasil, con miras a la innovación tecnológica y la apropiación del conocimiento científico de carácter técnico, económico, ambiental y social.
			<br><br>
			Entre sus objetivos se encuentran la promoción del sistema de investigación agropecuaria y el sistema de asistencia técnica y extensión rural, que promueven la mejora y la generación de nuevas tecnologías. Además, realiza la acreditación de personas naturales y jurídicas que prestan servicios de asistencia técnica y extensión rural.
			<br><br>
			Uno de los desafíos de Anater es reorganizar el sistema de asistencia técnica y extensión rural (ATER) para asegurar un mayor acceso al servicio a los productores de todo Brasil, especialmente a los agricultores familiares, que son responsables de un 70% de los alimentos que llegan a la mesa de los brasileños.
			<br><br>
			Enfocada en la viabilidad y calificación del servicio ATER en todo el territorio nacional, Anater tiene una importante participación en el Observatorio de la Agropecuaria Brasileña, proporcionando datos sobre los contratos firmados entre la Agencia y las entidades prestadoras de servicios de ATER.
			<br><br>
			La integración de esta información con la de otras bases del Observatorio permitirá componer paneles y mapas orientados a analizar el comportamiento de la asistencia técnica en todo el país, de la aplicación de técnicas que contribuyan al desarrollo rural sostenible y del monitoreo del desarrollo de los cultivos como resultado de los trabajos de calificación.`,
    },
    bank: {
      title: 'Banco Central de Brasil',
      description: `El Banco Central de Brasil (Bacen) es una autarquía federal vinculada al Ministerio de Economía, cuya misión es asegurar la estabilidad del poder de compra de la moneda y un sistema financiero sólido y eficiente. Además, la institución es responsable de ejecutar la estrategia establecida por el Consejo Monetario Nacional (CMN) para mantener la inflación bajo control. Para garantizar el cumplimiento de estos importantes mandatos, la autarquía ha adoptado las mejores prácticas de gobernanza pública y reforzado continuamente sus valores institucionales.
			<br><br>
			Al poner a disposición los datos que posee, el Banco Central de Brasil participa en la Política de Datos Abiertos del Poder Ejecutivo Federal y cree que esta divulgación genera numerosos efectos positivos para la sociedad: fomenta el control social; racionaliza los gastos; y estimula la innovación en sectores que prestan servicios a la ciudadanía. La intención es ampliar y mejorar la transparencia activa por medio de la apertura de datos públicos, con eficiencia y calidad, para contribuir a reforzar la credibilidad y el cumplimiento de la misión institucional del Bacen, así como fomentar el control social, la mejora de la integridad y la gobernanza pública, la reducción de gastos y la participación social.
			<br><br>
			En la Agricultura y Ganadería, principal matriz económica brasileña, una parte importante del movimiento de recursos de financiación de la producción brasileña es registrada y monitoreada por el Banco Central, mediante el desarrollo, mantenimiento y evolución del Sicor - El Sistema de Operaciones de Crédito Rural y el Programa de Garantía de la Actividad Agropecuaria (Proagro). El Sistema registra todas las operaciones de crédito rural aprobadas por las instituciones financieras que forman parte del Sistema Nacional de Crédito Rural (SNCR), así como las de la clasificación de negocios en el Proagro.
			<br><br>
			Con la disponibilidad de datos del Banco Central y los ajustes de los servicios de integración de los datos abiertos, el Observatorio de la Agropecuaria Brasileña automáticamente actualiza, consolida y pone a disposición información sobre el desempeño del crédito rural brasileño, para todos las finalidades, programas, subprogramas y categorías de clasificación. Esto permite incluso interpretar las inversiones del Gobierno Federal en políticas públicas de fomento específicas. Mediante la aplicación de modernas técnicas de usabilidad, así como herramientas de interpretación y presentación gráfica de los datos de estadística descriptiva, el Observatorio de la Agropecuaria Brasileña pone a disposición de la sociedad, en un panel temático específico, los datos sobre el desempeño del crédito rural en Brasil en conjunto con otras informaciones estratégicas para la Agropecuaria Brasileña.`,
    },
    cna: {
      title: 'Confederación de la Agricultura y Ganadería de Brasil',
      description: `La Confederación de la Agricultura y Ganadería de Brasil (CNA) es una entidad sindical patronal que representa a 5 millones de productores rurales brasileños, de pequeño, mediano y gran tamaño, y de diversas ramas de actividad. Creada en 1951, la CNA se ha consolidado en los últimos años como el principal foro de discusión y decisión de la agroindustria brasileña. El Sistema CNA está conformado por las 27 federaciones de agricultura y ganadería, que operan en los estados y el Distrito Federal, más de 2.000 sindicatos rurales —responsables de las acciones de apoyo directo a los productores rurales en los municipios―, el Servicio Nacional de Aprendizaje Rural (SENAR) y el Instituto CNA.
			<br><br>
			La CNA congrega a asociaciones y líderes rurales y participa de forma activa y permanente en las discusiones y decisiones sobre la política agrícola nacional. En el cumplimiento de su misión institucional, la CNA representa, organiza y fortalece a los productores rurales brasileños, defendiendo sus derechos e intereses, promoviendo el desarrollo económico y social del sector agropecuario.
			<br><br>
			Como uno de los productos de la fructífera y estrecha alianza de la CNA con el gobierno federal, específicamente con el Ministerio de Agricultura, Ganadería y Abastecimiento (MAPA), está el Observatorio de la Agropecuaria Brasileña, en el que la CNA aporta inversiones directas, en el ámbito de la materialización física, en salas interactivas altamente tecnológicas y en la cocreación de las principales líneas de análisis, interpretación e integración de datos. Así, contribuye a la visión y las necesidades del productor rural en cuanto a la generación de conocimiento actualizado y accesible, para la definición de estrategias competitivas y sostenibles para la agropecuaria brasileña.`,
    },
    conab: {
      title: 'Compañía Nacional de Abastecimiento',
      description: `La Compañía Nacional de Abastecimiento (Conab) es una empresa pública vinculada al Ministerio de Agricultura, Ganadería y Abastecimiento (MAPA). Trabaja en dos grandes frentes: la generación de información sobre el agronegocio y la ejecución de políticas orientadas al abastecimiento y la seguridad alimentaria. Tiene superintendencia en todos los estados y también en el Distrito Federal, manteniendo activos a cientos de profesionales en todo Brasil. Conab también cuenta con 68 Unidades Almacenadoras distribuidas de manera estratégica.
			<br><br>
			Para fundamentar las acciones del agronegocio y también las políticas públicas relacionadas con el sector y la seguridad alimentaria, la Conab recopila, analiza y pone a disposición información periódicamente. Son estimaciones de producción agrícola, monitoreo climático y espectral de los cultivos, costos de producción, precios agropecuarios de insumos y fletes, análisis continuo de la relación de oferta y demanda de granos, entre otros trabajos.
			<br><br>
			Además, en 2020, Conab reforzó sus actividades en la ganadería, con enfoque en siete sectores: ganadería bovina de carne y de leche, pollos, cerdos, ovinos, caprinos y huevos. A partir de 2021 se realizará una difusión periódica de la información sobre la ganadería.
			<br><br>
			El desarrollo continuo del equipo de Conab es reforzado por la relación con aliados en el país y en el exterior, lo que posiciona las acciones de la compañía en convergencia con las metodologías y discusiones de vanguardia en la agricultura y ganadería mundial.
			<br><br>
			La Compañía Nacional de Abastecimiento es pionera en la alianza de integración de contenidos con el Observatorio de la Agropecuaria Brasileña. Tiene importancia estratégica como generadora y guardiana de una robusta base de datos, haciéndola accesible en sus portales y, ahora, en el Observatorio. A través de herramientas que integran diferentes bases de datos, difunde contenidos de interés estratégico para la planificación del agronegocio, para los sectores relacionados y para la definición y ejecución de políticas públicas.`,
    },
    embrapa: {
      title: 'Empresa Brasileña de Investigación Agropecuaria',
      description: `La Empresa Brasileña de Investigación Agropecuaria (Embrapa) es una organización pública de investigación, desarrollo e innovación que actúa en la generación de conocimiento y tecnologías para la producción de alimentos, fibras y fuentes de energía. Su misión es brindar soluciones de investigación, desarrollo e innovación para la sostenibilidad de la agricultura en beneficio de la sociedad brasileña.
			<br><br>
			Establecida por la Ley nº 5.851 de 1972, su principal objetivo es fortalecer su capacidad para responder a las demandas de la agropecuaria brasileña y, al mismo tiempo, anticipar y enfrentar los desafíos del futuro.
			<br><br>
			Es una empresa con espíritu global que, a lo largo de su historia, ha construido una sólida red de cooperación internacional. Actualmente está presente en todos los continentes. Tiene alianzas con algunas de las principales instituciones y redes de investigación del mundo, lo que también contribuye al programa de cooperación técnica del Gobierno brasileño, que busca transferir y adaptar tecnologías nacionales a la realidad tropical de diferentes países.
			<br><br>
			La visión de Embrapa es ser protagonista y aliada esencial en la generación y el uso de conocimiento para el desarrollo sostenible de la agricultura brasileña hasta 2030. En este sentido, cuenta con varios datos provenientes de estudios y programas en alianza con el MAPA y otras instituciones, cuyo conocimiento es fundamental para la toma de decisiones estratégicas, para el mercado y para el avance de la ciencia y las nuevas tecnologías.
			<br><br>
			La alianza entre Embrapa y el Observatorio se da mediante el suministro de información a través del panel temático del Programa Nacional de Suelos (PronaSolos), componente fundamental de la integración de datos del MAPA con el estudio e interpretación de los suelos brasileños. Además, se encuentra en proceso de estructuración el panel temático con las Megatendencias de la Ganadería de Carne para 2030.`,
    },
    ibge: {
      title: 'IBGE',
      description: `El Instituto Brasileño de Geografía y Estadística (IBGE) es la institución vinculada al Ministerio de Economía responsable de la producción y análisis de información estadística y geográfica, así como de la coordinación de los sistemas estadísticos y cartográficos de Brasil, constituyendo el principal proveedor de datos e información del país.
			<br><br>
			El IBGE tiene un volumen estupendo de datos sobre la agricultura brasileña. Produzen información económico-financiera, sobre la producción, los bienes y servicios consumidos y el empleo, entre otros aspectos, de las actividades de agricultura, ganadería, producción forestal, pesca y acuicultura. Así, estos datos son esenciales para el gobierno y los sectores involucrados, que se benefician de ellos para el conocimiento amplio de las cadenas, la toma de decisiones y, por parte del gobierno, la elaboración de políticas públicas.
			<br><br>
			Entre los trabajos esenciales del Instituto están la Encuesta Sistemática de la Producción Agropecuaria (LSPA), la Encuesta Trimestral de Leche, la Producción Agropecuaria Municipal (PAM), la Encuesta Pecuaria Municipal (PPM) y el Censo Agropecuario, que perfila toda la actividad de la agricultura y ganadería brasileña, aportando el número y superficie de los establecimientos, las actividades desarrolladas en ellos y las características del productor. Los datos del IBGE, en particular los del PAM, PPM y el Censo de 2017, se pueden encontrar en prácticamente todos los paneles del Observatorio. Esto refuerza el grado de importancia de estos datos para la visión amplia del sector agrícola, que se enriquece aún más con el cruce de datos de otras fuentes existentes en el Observatorio.`,
    },
    incra: {
      title: 'Instituto Nacional de Colonización y Reforma Agraria',
      description: `El Instituto Nacional de Colonización y Reforma Agraria (Incra) es una autarquía federal cuya principal misión es realizar la reforma agraria y llevar a cabo el ordenamiento agrario nacional.
			<br><br>
			Creado por el Decreto nº 1.110, del 9 de julio de 1970, el Incra tiene como objetivo posibilitar una distribución más igualitaria de la tierra, lograr la justicia social y fomentar el desarrollo social y económico en el campo, con acciones y programas que aseguren la oportunidad de acceso a la propiedad de la tierra, sujeta a su función social.
			<br><br>
			Actualmente, el Instituto está presente en todo el territorio nacional. Tiene 29 superintendencias regionales y 49 unidades avanzadas y busca ser un referente internacional en soluciones de inclusión social. También cuenta con alianzas con gobiernos estatales y municipales, a los que ofrece instrumentos que posibilitan la llegada de los servicios autárquicos a los productores rurales y la transformación de su realidad y la de sus familias.
			<br><br>
			El Incra cuenta con bases de datos muy relevantes para la gestión eficiente de la agropecuaria en el país, como el Sistema Nacional de Catastro Rural (SNCR) y el Catastro Nacional de Inmuebles Rurales (CNIR). Ellas Ellos tienen un carácter estructural, ya que sus datos se contrastan con los de otras instituciones, lo que genera información que puede calificar las políticas públicas y sirve como instrumento de fiscalización.
			<br><br>
			La alianza entre el Instituto y el Observatorio, que comienza con el uso de los datos del Sistema de Gestión Agraria (SIGEF), es fundamental para la obtención de información segura y consolidada, ya que busca asegurar que esta y otras bases de Incra y de otros aliados sean estudiadas, integradas y puestas a disposición.`,
    },
    inmet: {
      title: 'Instituto Nacional de Meteorología',
      description: `La misión del Instituto Nacional de Meteorología (Inmet), organismo del Ministerio de Agricultura, Ganadería y Abastecimiento, es brindar información meteorológica a la sociedad brasileña e incidir de manera constructiva en el proceso de toma de decisiones, contribuyendo al desarrollo sostenible del país. Esta tarea se logra mediante el monitoreo, análisis y pronóstico del tiempo y el clima, que se basan en la investigación aplicada, el trabajo en alianza y el intercambio de conocimientos, con énfasis en resultados prácticos y confiables.
			<br><br>
			Son atribuciones de Inmet: elaborar y difundir diariamente, a nivel nacional, el pronóstico del tiempo, avisos y boletines meteorológicos especiales; promover la realización de estudios y relevamientos meteorológicos y climatológicos aplicados a la agricultura y otras actividades afines; coordinar, elaborar y ejecutar programas y proyectos de investigación agrometeorológica y de monitoreo de las variaciones climáticas; establecer, coordinar y operar las redes de observación meteorológica y transmisión de datos, incluidas las integradas en la red internacional; proponer la programación y monitorear la implementación de la capacitación y formación de los recursos humanos, en respuesta a demandas técnicas específicas.
			<br><br>
			Inmet representa a Brasil ante la Organización Meteorológica Mundial (OMM) y, a través de su delegación, es responsable del tráfico de los mensajes recopilados por la red de observación meteorológica de América del Sur y los demás centros meteorológicos que conforman el Sistema de Vigilancia Meteorológica Mundial.
			<br><br>
			En el Observatorio de la Agropecuaria Brasileña, Inmet alberga los sistemas y las aplicaciones del Observatorio en la infraestructura de tecnología de la información del Instituto. Además, contribuye a la difusión de información a través de modernos y seguros servicios automatizados de consulta e intercambio de datos meteorológicos.`,
    },
    lapig: {
      title: 'LAPIG',
      description: `El Laboratorio de Procesamiento de Imágenes y Geoprocesamiento (Lapig) de la Universidad Federal de Goiás (UFG) es un importante frente de acción vinculado a las actividades de enseñanza y extensión. A través de alianzas establecidas con importantes universidades y centros de investigación, en Brasil y en el exterior, produce y/u organiza datos geográficos y documentales destinados al monitoreo territorial y ambiental de los biomas brasileños y sus respectivos paisajes naturales y humanos.
			<br><br>
			Con la misión de contribuir para que la información geográfica y ambiental sea útil y accesible, y con miras a ser una referencia internacional en la producción y organización de datos geográficos, así como en el desarrollo de soluciones para una mejor gobernanza territorial y ambiental de los paisajes brasileños, el LAPIG ha producido información muy relevante para estudios y análisis de datos geográficos en el país.
			<br><br>
			El Atlas de Pastos es una herramienta desarrollada por Lapig que tiene datos relacionados con los pastos brasileños, con informaciones geográficamente especializadas. Entre los datos disponibles se encuentran áreas de pastos, calidad, stock de carbono en los pastos, entre otros, con mapeos basados ​​en datos Landsat que permiten visualizar los niveles de degradación de los pastos, obtener estimaciones de stock de carbono en el suelo en las raíces y biomasa aérea de pastos en el bioma Cerrado. Como contienen información muy relevante para la agricultura sostenible, se incorporaron a las capas del panel de Agricultura Sostenible y Medio Ambiente del Observatorio, y se pueden cruzar con decenas de otras capas, lo que permite ampliar el conocimiento sobre el tema y sus variantes.`,
    },
    observarei: {
      title: 'Observarei',
      description: `El OBSERVAREI - El Observatorio de Emprendimiento Innovador tiene como objetivo principal el desarrollo económico de la región de Amurel a través de iniciativas construidas sobre la estrategia de creación, desarrollo, expansión y consolidación de ecosistemas de innovación en los municipios que conforman la región de AMUREL.`,
    },
    sfb: {
      title: 'Servicio Forestal Brasileño',
      description: `El Servicio Forestal Brasileño (SFB), organismo especializado en bosques vinculado al Ministerio de Agricultura, Ganadería y Abastecimiento (MAPA), tiene la misión de promover el conocimiento, el uso sostenible y la ampliación de la cobertura forestal en Brasil, haciendo que la agenda forestal sea estratégica para la economía del país. Su misión institucional es ser reconocido por su innovación, agilidad y calidad en la implementación de políticas públicas, además de brindar servicios para el desarrollo sostenible de las cadenas productivas agropecuarias. Actualmente, el SFB desarrolla, alimenta y administra varias bases de datos para las estrategias de desarrollo agropecuario y forestal sostenible en Brasil.
			<br><br>
			Es mantenedor del SNIF (Sistema Nacional de Información Forestal), que centraliza datos sobre la producción forestal, y del Inventario Nacional Forestal (IFN), con base de datos primarios sobre la vegetación y las variables sociales y físicas relacionadas con los bosques nativos de Brasil.
			<br><br>
			Además, el IFN proporciona información sobre las concesiones forestales. Entre ellas se destaca la base de datos del Sicar (Sistema de Catastro Ambiental Rural), a través del cual los productores rurales brasileños, de manera autodeclarada, pusieron a disposición información 100% georreferenciada sobre el perímetro y la disponibilidad de vegetación nativa en su posesión y en predios rurales. También se insertan en él datos sobre la macroplanificación del uso del suelo en el inmueble rural, con la identificación de sus áreas de preservación permanente y reservas legales, bajo los auspicios del Código Forestal Brasileño (Ley Federal 12.651/2012).
			<br><br>
			En interfaz con el Observatorio de la Agropecuaria Brasileña, el SFB/MAPA se configura como una institución muy relevante y estratégica, teniendo en cuenta que Sicar es la base de datos territoriales sobre inmuebles rurales más grande del mundo. Además, es una base de datos fundamental, desde un punto de vista estratégico, para la definición y planificación de políticas públicas basadas en el desarrollo rural integrado y cuenta con las más modernas estrategias de inteligencia territorial y geoespacial.
			<br><br>
			Entre las atribuciones del Servicio Forestal Brasileño, en línea con las directrices estratégicas de gestión del MAPA, también se encuentra la promoción de la expansión de la cobertura vegetal nativa, la producción sostenible y la regularización ambiental de los inmuebles rurales en Brasil. Con base en los datos forestales y territoriales del SFB, que abarcan todo el país, junto con otras bases de datos integradas en el Observatorio de la Agropecuaria Brasileña, será posible analizar tendencias y oportunidades para la implementación de las políticas públicas, iniciativas de producción rural sostenible y pago por servicios ambientales, desde los bosques en inmuebles rurales en Brasil.`,
    },
  },
  library: {
    text_01:
      'Biblioteca con filtros, que contiene varios archivos, y para cada publicación el tipo, título, fuente, formato de archivo y su referencia de tiempo para descargar.',
    macro_01: 'Agricultura Digital',
    macro_02: 'Boletines climáticos para la agricultura',
    macro_03: 'Documentos generales',
    macro_04: 'Documentos Técnicos',
    macro_05: 'Gestión de la producción agrícola',
    macro_06: 'Previsiones de lo Agronegocio en Brasil',
    macro_07: 'Reforma Agraria',
    macro_08: 'Sostenibilidad y medio ambiente',
  },
  metadata: {
    text_01:
      'Aquí puede encontrar los Metadatos básicos del Observatorio, separados en Plataforma Geoespacial y Plataforma Estadística. Los metadatos son datos que describen los datos, lo que permite conocer la colección, así como gestionarla.',
  },
  platforms: {
    text_01: `La Plataforma Estadística es un entorno del Observatorio que proporciona información de interés del sector agropecuario brasileño, a través de datos numéricos, tabulares y representaciones gráficas sobre los diversos temas tratados. La Plataforma está organizada con filtros por período, estratificaciones a nivel nacional, estatal y municipal, así como datos cuantitativos y cualitativos. Los datos son proporcionados por fuentes oficiales, abiertos al público, con un enfoque profundo de las variables presentadas, en diferentes niveles, permitiendo al usuario interactuar y estudiar mejor la información seleccionada.`,
    text_02: `La Plataforma Geoespacial es un entorno del Observatorio dedicado a la integración de datos e información territoriales de interés para el Sector Agropecuario Brasileño, a través de los cuales se pueden visualizar y construir datos geoespaciales de acuerdo con las necesidades e interpretación de los usuarios. La plataforma está organizada por entorno de visualización de capas, informes cuantitativos y paneles temáticos que tengan cualquier tipo de atributo y representación cartográfica de los datos. La Plataforma Geoespacial cuenta con varias bases de datos georreferenciados de fuentes oficiales, abiertos al público, en un mismo entorno y con herramientas de interactividad integradas.`,
    text_03: `La herramienta Informes Dinámicos del Observatorio Agrícola es la herramienta más completa y avanzada disponible para crear informes amplios, completos y fáciles de entender, organizados y ordenados de forma simplificada y fácil de exportar datos.`,
    text_04: 'Acceder a la plataforma de informes',
    text_05: 'Acceder a todas las plataformas',
  },
  footer: {
    address: 'Dirección',
    rights: '© 2022 Todos los derechos reservados.',
  },
}

const Translations = { ptBR: ptBR, eng: eng, esp: esp }
export default Translations
